import { DoBootstrap, NgModule, inject } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import 'zone.js';
import 'reflect-metadata';
import { UpgradeModule } from '@angular/upgrade/static';
import { UIRouterUpgradeModule, NgHybridStateDeclaration } from "@uirouter/angular-hybrid";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling'; 
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider'; 
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { EditorModule } from '@tinymce/tinymce-angular';

import { LOCALE_ID } from '@angular/core';
import 'moment/locale/en-au';

import { AuthInterceptor } from './http-interceptor';
import { AppComponent } from './app.component';

import { ManageSubCategoriesComponent } from './manage-subcategories/manage-subcategories.component';
import { ManageCategoriesComponent } from './manage-categories/manage-categories.component';
import { ManageContactsComponent } from './manage-contacts/manage-contacts.component';
import { ManageSubscriberComponent } from './manage-subscriber/manage-subscriber.component';
import { ManageSkillsComponent } from './manage-skills/manage-skills.component';
import { ManageDepartmentsComponent } from './manage-departments/manage-departments.component';
import { ManageVolunteersComponent } from './manage-volunteers/manage-volunteers.component';
import { ManageTrainingComponent } from './manage-training/manage-training.component';
import { ManageVolunteerDepartmentsComponent } from './manage-volunteer-departments/manage-volunteer-departments.component';
import { ManagePositionsComponent } from './manage-postitions/manage-positions.component';
import { ManageQualificationsComponent } from './manage-qualifications/manage-qualifications.component';
import { ManageSubtypesComponent } from './manage-subtypes/manage-subtypes.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ManageProgramsComponent } from './manage-programs/manage-programs.component';
import { ManagePrizesComponent } from './manage-prizes/manage-prizes.component';

import routingConfig from './ajs/controllers/acl';
import { ZeddersComponent } from './zedders/zedders.component';
import { AvailabilityToggleComponent } from './availability-toggle/availability-toggle.component';
import { AlbumTrackListComponent } from './album-track-list/album-track-list.component';
import { AlbumInfoComponent } from './album-info/album-info.component';
import { CoverPlayerComponent } from './cover-player/cover-player.component';
import { ReleasesArchiveComponent } from './releases-archive/releases-archive.component';
import { MonthCalendarComponent } from './month-calendar/month-calendar.component';
import { ReleasesDetailsComponent } from './releases-details/releases-details.component';
import { FileDndDirective } from './file-dnd.directive';
import { HmsPipe } from './hms.pipe';
import { BytesPipe } from './bytes.pipe';
import { FelaBrowserComponent } from './fela-browser/fela-browser.component';
import { BackslashPipe } from './backslash.pipe';
import { TagEditorComponent } from './tag-editor/tag-editor.component';
import { SortPipe } from './sort.pipe';
import { ReleasesComponent } from './releases/releases.component';
import { ArtistInfoComponent } from './artist-info/artist-info.component';
import { ReleasesArtistsComponent } from './releases-artist/releases-artist.component';
import { ManageReleasesComponent } from './manage-releases/manage-releases.component';
import { ManageTagsComponent } from './manage-tags/manage-tags.component';
import { DonatedSubscriptionsComponent } from './donated-subscriptions/donated-subscriptions.component';
import { SubscriberDetailsComponent } from './subscriber-details/subscriber-details.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { VolunteerDetailsComponent } from './volunteer-details/volunteer-details.component';
import { JoinPipe } from './join.pipe';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { OrdersComponent } from './orders/orders.component';
import { ZedDigitalComponent } from './zed-digital/zed-digital.component';
import { SimpleMetadataEditorComponent } from './simple-metadata-editor/simple-metadata-editor.component';
import { M3uPlaylistComponent } from './m3u-playlist/m3u-playlist.component';
import { RenameDialogComponent } from './rename-dialog/rename-dialog.component';
import { SendToBruceDialogComponent } from './send-to-bruce-dialog/send-to-bruce-dialog.component';
import { SpotRosterComponent } from './spot-roster/spot-roster.component';
import { FilterPipe } from './filter.pipe';
import { SponsorshipCommsDialogComponent } from './sponsorship-comms-dialog/sponsorship-comms-dialog.component';
import { SponsorshipSchedulerComponent } from './sponsorship-scheduler/sponsorship-scheduler.component';
import { LiveReadsDialogComponent } from './live-reads-dialog/live-reads-dialog.component';
import { SponsorshipNotesEditorDialogComponent } from './sponsorship-notes-editor-dialog/sponsorship-notes-editor-dialog.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { CampaignDetailsComponent } from './campaign-details/campaign-details.component';
import { ProgramSchedulerComponent } from './program-scheduler/program-scheduler.component';
import { ProgramDialogComponent } from './program-dialog/program-dialog.component';
import { GridSaveDialogComponent } from './grid-save-dialog/grid-save-dialog.component';
import { ProgramPlaylistsComponent } from './program-playlists/program-playlists.component';
import { AnnouncerProgramsComponent } from './announcer-programs/announcer-programs.component';
import { NgChartsModule } from 'ng2-charts';
import { OrdersReportComponent } from './orders-report/orders-report.component';
import { SubscribersReportComponent } from './subscribers-report/subscribers-report.component';
import { ProgramsReportComponent } from './programs-report/programs-report.component';
import { MergeZeddersComponent } from './merge-zedders/merge-zedders.component';
import { OnAirStudioComponent } from './on-air-studio/on-air-studio.component';
import { StudioDelegationComponent } from './studio-delegation/studio-delegation.component';
import { MergeContactsComponent } from './merge-contacts/merge-contacts.component';
import { MergeArtistsComponent } from './merge-artists/merge-artists.component';
import { OrdersAdminToolsComponent } from './orders-admin-tools/orders-admin-tools.component';
import { MerchInventoryComponent } from './merch-inventory/merch-inventory.component';
import { MerchItemDetailsComponent } from './merch-item-details/merch-item-details.component';
import { HomeComponent } from './home/home.component';
import { AnnouncersTemporaryComponent } from './announcers-temporary/announcers-temporary.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PlayerComponent } from './player/player.component';
import { ZedletterComponent } from './zedletter/zedletter.component';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';
import { AuthenticationService } from './authentication.service';
import { PromoService } from './promo.service';
import { Transition } from '@uirouter/core';
import { ContactService } from './contact.service';
import { VolunteerService } from './volunteer.service';
import { SubService } from './sub.service';
import { OrdersService } from './orders.service';
import { ReleaseService } from './release.service';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

import { PendingChangesGuard } from './pending-changes.guard';
import { NavScrollDirective } from './nav-scroll.directive';
import { AnnouncerPlaylistsComponent } from './announcer-playlists/announcer-playlists.component';
import { AnnouncerPlaylistDialogComponent } from './announcer-playlist-dialog/announcer-playlist-dialog.component';
import { TrackListComponent } from './track-list/track-list.component';

// export function getNgHttp($injector) {
//   return $injector.get('$http');
// }
// export function getInterestsService($injector) {
//   return $injector.get('InterestsService');
// }
// export function getDepartmentsService($injector) {
//   return $injector.get('DepartmentsService');
// }
// export function getContactCategoriesService($injector) {
//   return $injector.get('ContactCategoriesService');
// }
// export function getSkillsNewService($injector) {
//   return $injector.get('SkillsNewService');
// }
// export function getSubtypesService($injector) {
//   return $injector.get('SubtypesService');
// }
// export function getProgramsService($injector) {
//   return $injector.get('ProgramsService');
// }
// export function getPrizesService($injector) {
//   return $injector.get('PrizesService');
// }
// export function getVolDepartmentsService($injector) {
//   return $injector.get('VolDepartmentsService');
// }
// export function getQualificationService($injector) {
//   return $injector.get('QualificationService');
// }
// export function getPositionsService($injector) {
//   return $injector.get('PositionsService');
// }
// export function getAuthenticationService($injector) {
//   return $injector.get('AuthenticationService');
// }
// export function getZedderService($injector) {
//   return $injector.get('ZedderService');
// }
// export function getCountriesService($injector) {
//   return $injector.get('CountriesService');
// }
// export function getSocket($injector) {
//   return $injector.get('socket');
// }
// export function getSessionService($injector) {
//   return $injector.get('SessionService');
// }
// export function getPlaylistService($injector) {
//   return $injector.get('PlaylistService');
// }
// export function getKeywordsService($injector) {
//   return $injector.get('KeywordsService');
// }

var access = routingConfig.accessLevels;

export const routes: Routes = [

  { 
    path: '',
    title: 'Home',
    component: HomeComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('public')
    }]
  },

  {
    path: 'login',
    title: 'Login',
    component: LoginComponent,
    // canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
    //   return inject(AuthenticationService).canActivate('*')
    // }]
  },

  {
    path: 'logout',
    title: 'Signed Out',
    component: LogoutComponent,
    resolve: { 
      promo: (route, state) => {
        return inject(AuthenticationService).logout();
      } 
    },
  },

  {
    path: 'releases',
    component: ReleasesComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('public')
    }],
    children: [
      {
        path: 'browse',
        title: 'New Releases',
        component: ReleasesArchiveComponent,
        data: { type: 'releases/browse' },
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
          return inject(AuthenticationService).canActivate('public')
        }]
      },
  
      {
        path: 'browse/:month',
        title: 'New Releases - By Month',
        component: ReleasesArchiveComponent,
        data: { type: 'releases/browse' },
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
          return inject(AuthenticationService).canActivate('public')
        }]
      },
    
      {
        path: 'browse/:month/:endMonth',
        title: 'New Releases - By Month',
        component: ReleasesArchiveComponent,
        data: { type: 'releases/browse' },
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
          return inject(AuthenticationService).canActivate('public')
        }]
      },
    
      {
        path: 'archive',
        title: 'Music Archive',
        component: ReleasesArchiveComponent,
        data: { type: 'releases/archive' },
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
          return inject(AuthenticationService).canActivate('public')
        }]
      },
    
      {
        path: 'archive/:month',
        title: 'Music Archive - By Month',
        component: ReleasesArchiveComponent,
        data: { type: 'releases/archive' },
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
          return inject(AuthenticationService).canActivate('public')
        }]
      },
    
      {
        path: 'archive/:month/:endMonth',
        title: 'Music Archive - By Month',
        component: ReleasesArchiveComponent,
        data: { type: 'releases/archive' },
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
          return inject(AuthenticationService).canActivate('public')
        }]
      },

      {
        path: 'artists/:artist',
        title: 'Artist Info',
        component: ReleasesArtistsComponent,
        data: { type: 'releases/artists' },
        resolve: { 
          releases: (route, state) => {
            return []
          } 
        },
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
          return inject(AuthenticationService).canActivate('public')
        }]
      },
    
      {
        path: ':id',
        title: 'Release Details',
        component: ReleasesDetailsComponent,
        data: { type: 'releases/details' },
        resolve: { 
          release: (route, state) => {
            const id = route.paramMap.get('id');
            if (id === 'search' || id === 'new') {
              let release:any = {};
              return release;
            }
            else {
              return inject(ReleaseService).get(id)
            }
          } 
        },
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
          return inject(AuthenticationService).canActivate('user')
        }]
      },
    ]
  },


  {
    path: 'playlists',
    title: 'Playlists',
    component: AnnouncerPlaylistsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('public')
    }]
  },

  {
    path: 'playlists/:id',
    title: 'Playlists',
    component: AnnouncerPlaylistsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('public')
    }]
  },
  
  { 
    path: 'related-artists',
    title: 'Related Artists Chart',
    loadChildren: () => import('./extras/extras.module').then(m => { 
      console.log(m);
      return m.ExtrasModule
    }),
  },

  {
    title: 'Zedder',
    path: 'zedders/:id',
    component: ZeddersComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('reception')
    }]
  },

  {
    path: 'orders',
    title: 'Orders',
    component: OrdersComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('reception')
    }],
    children: [
      { 
        path: ':id',
        component: OrderDetailsComponent,
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
          return inject(AuthenticationService).canActivate('reception')
        }],
        resolve: { 
          order: (route, state) => {
            const id = route.paramMap.get('id');
            if (id === 'search' || id === 'new') {
              let order:any = {};
              return order;
            }
            else {
              return inject(OrdersService).get(id)
            }
          } 
        },
      },
    ]
  },

  {
    path: 'subscribers/:id',
    title: 'Subscribers',
    component: SubscriberDetailsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('reception')
    }],
    resolve: { 
      subscriber: (route, state) => {
        const id = route.paramMap.get('id');
        if (id === 'search' || id === 'new') {
          let subscriber:any = { zedder: {} };
          return subscriber;
        }
        else {
          return inject(SubService).get(id)
        }
      } 
    },
  },

  {
    path: 'volunteers/:id',
    title: 'Volunteers',
    component: VolunteerDetailsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('reception')
    }],
    resolve: { 
      volunteer: (route, state) => {
        const id = route.paramMap.get('id');
        if (id === 'search' || id === 'new') {
          let volunteer:any = { zedder: {} };
          return volunteer;
        }
        else {
          return inject(VolunteerService).get(id)
        }
      } 
    },
  },

  { 
    path: 'contacts/:id',
    title: 'Contacts',
    component: ContactDetailsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('reception')
    }],
    resolve: { 
      contact: (route, state) => {
        const id = route.paramMap.get('id');
        if (id === 'search' || id === 'new') {
          let contact:any = { contactcategories: [], individuals: [] };
          return contact;
        }
        else {
          return inject(ContactService).get(id)
        }
      } 
    },
  },

  { 
    path: 'sponsorship',
    title: 'Sponsorship Campaigns',
    component: CampaignListComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  { 
    path: 'sponsorship/new/promo',
    title: 'Sponsorship New Campaign',
    component: CampaignDetailsComponent,
    resolve: { 
      promo: (route, state) => {
        return { campaigns: [], scheduled: [], contact: {} };
      } 
    },
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'sponsorship/spot-roster',
    title: 'Sponsorship Spot Roster',
    component: SpotRosterComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('public')
    }]
  },

  { 
    path: 'sponsorship/schedule',
    title: 'Sponsorship Spot Schedule',
    component: SponsorshipSchedulerComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      console.log( access.user ); 
      console.log( route, state); 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'sponsorship/:id',
    title: 'Sponsorship Campaign Details',
    component: CampaignDetailsComponent,
    resolve: { 
      promo: (route, state) => {
        const id = route.paramMap.get('id');
        return inject(PromoService).get(id)
      } 
    },
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  { 
    path: 'reports/subscribers',
    title: 'Subscriber Report',
    component: SubscribersReportComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  { 
    path: 'reports/orders',
    title: 'Orders Report',
    component: OrdersReportComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  { 
    path: 'reports/programs',
    title: 'Programs Report',
    component: ProgramsReportComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'manage/subscribers',
    title: 'Subscibers Field Editor',
    component: ManageSubscriberComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'manage/volunteers',
    title: 'Volunteers Field Editor',
    component: ManageVolunteersComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'manage/contacts',
    title: 'Contacts Field Editor',
    component: ManageContactsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'manage/releases',
    title: 'Releases Field Editor',
    component: ManageReleasesComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'merge/contacts',
    title: 'Merge Contacts',
    component: MergeContactsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  { 
    path: 'merge/zedders',
    title: 'Merge Zedders',
    component: MergeZeddersComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'manage/orders',
    title: 'Merge Orders',
    component: OrdersAdminToolsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'zedletter',
    title: 'Zedletter Editor',
    component: ZedletterComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }],
    canDeactivate: [PendingChangesGuard]
  },

  {
    path: 'schedule',
    title: 'Program Schedule Editor',
    component: ProgramSchedulerComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  { 
    path: 'merge/artists',
    title: 'Merge Artists',
    component: MergeArtistsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  { 
    path: 'merch/inventory',
    title: 'Merch Inventory',
    component: MerchInventoryComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'donated-subscriptions',
    title: 'Add Donated Subscriber',
    component: DonatedSubscriptionsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin')
    }]
  },

  {
    path: 'emergency-broadcast',
    title: 'Emergency Broadcast',
    component: ZedDigitalComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin') || inject(AuthenticationService).canActivate('announcer')
    }]
  },

  {
    path: 'zed-digital',
    title: 'Zed Digital Programs',
    component: ZedDigitalComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('admin') || inject(AuthenticationService).canActivate('announcer')
    }]
  },

  {
    path: 'announcers/temporary',
    title: 'Program Temporary Folders',
    component: AnnouncersTemporaryComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('announcer')
    }]
  },

  {
    path: 'announcers/programs',
    title: 'Playlist Editor',
    component: AnnouncerProgramsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => { 
      return inject(AuthenticationService).canActivate('announcer')
    }],
    canDeactivate: [PendingChangesGuard]
  },

  { path: '**', component: NotFoundComponent, title: 'Not Found' },
]




@NgModule({
  declarations: [
    ManageSubCategoriesComponent,
    ManageContactsComponent,
    ManageCategoriesComponent,
    ManageSubscriberComponent,
    ManageSkillsComponent,
    ManageTrainingComponent,
    ManageVolunteerDepartmentsComponent,
    ManageVolunteersComponent,
    ManageSubtypesComponent,
    ManageProgramsComponent,
    ManageDepartmentsComponent,
    ManagePrizesComponent,
    ManagePositionsComponent,
    ManageQualificationsComponent,
    ZeddersComponent,
    AvailabilityToggleComponent,
    AlbumTrackListComponent,
    AlbumInfoComponent,
    CoverPlayerComponent,
    ReleasesArchiveComponent,
    MonthCalendarComponent,
    ReleasesDetailsComponent,
    FileDndDirective,
    HmsPipe,
    BytesPipe,
    FelaBrowserComponent,
    BackslashPipe,
    TagEditorComponent,
    SortPipe,
    ReleasesComponent,
    ArtistInfoComponent,
    ReleasesArtistsComponent,
    ManageReleasesComponent,
    ManageTagsComponent,
    DonatedSubscriptionsComponent,
    SubscriberDetailsComponent,
    ContactDetailsComponent,
    VolunteerDetailsComponent,
    JoinPipe,
    OrderDetailsComponent,
    ConfirmationDialogComponent,
    OrdersComponent,
    ZedDigitalComponent,
    SimpleMetadataEditorComponent,
    M3uPlaylistComponent,
    RenameDialogComponent,
    SendToBruceDialogComponent,
    SpotRosterComponent,
    FilterPipe,
    SponsorshipCommsDialogComponent,
    SponsorshipSchedulerComponent,
    LiveReadsDialogComponent,
    SponsorshipNotesEditorDialogComponent,
    CampaignListComponent,
    CampaignDetailsComponent,
    ProgramSchedulerComponent,
    ProgramDialogComponent,
    GridSaveDialogComponent,
    ProgramPlaylistsComponent,
    AnnouncerProgramsComponent,
    OrdersReportComponent,
    SubscribersReportComponent,
    ProgramsReportComponent,
    MergeZeddersComponent,
    OnAirStudioComponent,
    StudioDelegationComponent,
    MergeContactsComponent,
    MergeArtistsComponent,
    OrdersAdminToolsComponent,
    MerchInventoryComponent,
    MerchItemDetailsComponent,
    HomeComponent,
    AnnouncersTemporaryComponent,
    LoginComponent,
    LogoutComponent,
    PlayerComponent,
    ZedletterComponent,
    SaveDialogComponent,
    AppComponent,
    NotFoundComponent,
    NavScrollDirective,
    AnnouncerPlaylistsComponent,
    AnnouncerPlaylistDialogComponent,
    TrackListComponent
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    RouterModule.forRoot(routes, {useHash: false, enableTracing: false, initialNavigation: 'enabledBlocking'}),
    // UIRouterModule.forRoot({ states: states }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatButtonModule,
    MatSortModule,
    MatTableModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatListModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatRadioModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatExpansionModule,
    OverlayModule,
    ScrollingModule,
    DragDropModule,
    EditorModule,
    NgChartsModule
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU'},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: LOCALE_ID, useValue: "en-AU" }, 
  // { 
  //   provide: 'AuthenticationService', 
  //   deps: ['$injector'],
  //   useFactory: getAuthenticationService 
  // },
  // { 
  //   provide: 'socket', 
  //   deps: ['$injector'],
  //   useFactory: getSocket 
  // },
  // { 
  //   provide: '$http', 
  //   deps: ['$injector'],
  //   useFactory: getNgHttp 
  // },
  // { 
  //   provide: 'InterestsService', 
  //   deps: ['$injector'],
  //   useFactory: getInterestsService 
  // },
  // { 
  //   provide: 'DepartmentsService', 
  //   deps: ['$injector'],
  //   useFactory: getDepartmentsService 
  // },

  // { 
  //   provide: 'VolunteerDepartmentsService', 
  //   deps: ['$injector'],
  //   useFactory: getVolDepartmentsService 
  // },
  // { 
  //   provide: 'ContactCategoriesService', 
  //   deps: ['$injector'],
  //   useFactory: getContactCategoriesService 
  // },
  // { 
  //   provide: 'SkillsNewService', 
  //   deps: ['$injector'],
  //   useFactory: getSkillsNewService
  // },
  // { 
  //   provide: 'SubtypesService', 
  //   deps: ['$injector'],
  //   useFactory: getSubtypesService
  // },
  // { 
  //   provide: 'ProgramsService', 
  //   deps: ['$injector'],
  //   useFactory: getProgramsService
  // },
  // { 
  //   provide: 'PrizesService', 
  //   deps: ['$injector'],
  //   useFactory: getPrizesService
  // },
  // { 
  //   provide: 'PositionsService', 
  //   deps: ['$injector'],
  //   useFactory: getPositionsService
  // },
  // { 
  //   provide: 'QualificationService', 
  //   deps: ['$injector'],
  //   useFactory: getQualificationService
  // },
  // { 
  //   provide: 'ZedderService', 
  //   deps: ['$injector'],
  //   useFactory: getZedderService
  // },
  // {
  //   provide: 'CountriesService', 
  //   deps: ['$injector'],
  //   useFactory: getCountriesService
  // },
  // {
  //   provide: 'SessionService', 
  //   deps: ['$injector'],
  //   useFactory: getSessionService
  // },
  // {
  //   provide: 'PlaylistService', 
  //   deps: ['$injector'],
  //   useFactory: getPlaylistService
  // },
  // {
  //   provide: 'KeywordsService', 
  //   deps: ['$injector'],
  //   useFactory: getKeywordsService
  // }
  ],
  bootstrap: [AppComponent]
})
// export class AppModule {}
export class AppModule {

  constructor(private upgrade: UpgradeModule, matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) { 
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
    matIconRegistry.addSvgIcon('4zzz-icon', domSanitizer.bypassSecurityTrustResourceUrl('/assets/4zzz-cutout-white.svg'));
    matIconRegistry.addSvgIcon('mairlist', domSanitizer.bypassSecurityTrustResourceUrl('./assets/mairlist-icon.svg'));
    matIconRegistry.addSvgIcon('local', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-local.svg'));
    matIconRegistry.addSvgIcon('australian', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-australian.svg'));
    matIconRegistry.addSvgIcon('female', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-female.svg'));
    matIconRegistry.addSvgIcon('gender_non_conforming', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-gender-non-conforming.svg'));
    matIconRegistry.addSvgIcon('new_zealand', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-new-zealand.svg'));
    matIconRegistry.addSvgIcon('new', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-new.svg'));
    matIconRegistry.addSvgIcon('indigenous', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-indigenous.svg'));
    matIconRegistry.addSvgIcon('local_outlined', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-local-outlined.svg'));
    matIconRegistry.addSvgIcon('australian_outlined', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-australian-outlined.svg'));
    matIconRegistry.addSvgIcon('female_outlined', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-female-outlined.svg'));
    matIconRegistry.addSvgIcon('gender_non_conforming_outlined', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-gender-non-conforming-outlined.svg'));
    matIconRegistry.addSvgIcon('new_zealand_outlined', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-new-zealand-outlined.svg'));
    matIconRegistry.addSvgIcon('new_outlined', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-new-outlined.svg'));
    matIconRegistry.addSvgIcon('indigenous_outlined', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-indigenous-outlined.svg'));
    matIconRegistry.addSvgIcon('subcard', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon-subcard.svg'));
    
  }
  // ngDoBootstrap() {
  //   this.upgrade.bootstrap(document.body, [app.name], { strictDi: true });
  // }
}


export const states = [
  // {
  //   name: 'directories',
  //   url: '/contact/manage',
  //   component: ManageContactsComponent,
  //   access: access.user
  // },

  // {
  //   name: 'volunteer/manage',
  //   url: '/volunteer/manage',
  //   component: ManageVolunteersComponent,
  //   access: access.user
  // },

  // {
  //   url: '/releases',
  //   name: 'releases',
  //   component: ReleasesComponent,
  //   access: access.public
  // },

  // {
  //   name: 'zedders',
  //   url: '/zedders/:id',
  //   component: ZeddersComponent,
  //   access: access.user
  // },
  // { 
  //   name: 'relatedArtists.**',
  //   url: '/related-artists',
  //   // component: ManageContactsComponent,
  //   // loadChildren: '../test.module#TestModule',
  //   loadChildren: () => import('./test/test.module').then(m => { 
  //     console.log(m);
  //     return m.TestModule
  //   }),
  //   access: access.public
  // },
  // {
  //   name: 'login',
  //   url: '/login',
  //   component: LoginComponent,
  //   access:  access.public
  // },
  // {
  //   name: 'logout',
  //   url: '/logout',
  //   component: LogoutComponent,
  //   resolve: [
  //     {
  //       token: 'logout',
  //       deps: [AuthenticationService],
  //       resolveFn: (authenticationService: AuthenticationService) => authenticationService.logout()
  //     }
  //   ],
  //   access: access.user
  // },
  // {
  //   name: 'submanage',
  //   url: '/subscriber/manage',
  //   component: ManageSubscriberComponent,
  //   access: access.user
  // }
  // ,
  // {
  //   name: 'releasesmanage',
  //   url: '/release/manage',
  //   component: ManageReleasesComponent,
  //   access: access.user
  // },

  // {
  //   name: 'donatedSubscriptions',
  //   url: '/donated-subscriptions',
  //   component: DonatedSubscriptionsComponent,
  //   access: access.user
  // }
  // ,
  // { 
  //   name: 'inventory',
  //   url: '/merch/inventory',
  //   component: MerchInventoryComponent,
  //   access: access.user
  // },
  // { 
  //   name: 'programsReport',
  //   url: '/reports/programs',
  //   component: ProgramsReportComponent,
  //   access: access.user
  // },
  // { 
  //   name: 'subreport', 
  //   url: '/subscriber/report',
  //   component: SubscribersReportComponent,
  //   access: access.user
  // },
  // { name: 'ordersreport',
  //   url: '/report/orders',
  //   component: OrdersReportComponent,
  //   access: access.admin
  // },
  // { 
  //   name: 'mergeArtists',
  //   url: '/merge/artists',
  //   component: MergeArtistsComponent,
  //   access: access.admin
  // },
  // { 
  //   name: 'mergeZedders',
  //   url: '/merge/zedders',
  //   component: MergeZeddersComponent,
  //   access: access.admin
  // },
  // {
  //   name: 'contactmerge',
  //   url: '/merge/contacts',
  //   component: MergeContactsComponent,
  //   access: access.admin
  // },
  // { name: 'ordersmanage',
  //   url: '/manage/orders',
  //   component: OrdersAdminToolsComponent,
  //   access: access.admin
  // },
  // {
  //   name: 'schedule', 
  //   url: '/schedule',
  //   component: ProgramSchedulerComponent,
  //   access: access.user
  // },

  // { name: 'promo',
  //   url: '/sponsorship',
  //   component: CampaignListComponent,
  //   access: access.user
  // },
  // { name: 'spotroster',
  //   url: '/sponsorship/spot-roster',
  //   component: SpotRosterComponent,
  //   access: access.user
  // },
  // { 
  //   name: 'scheduler',
  //   url: '/sponsorship/schedule',
  //   component: SponsorshipSchedulerComponent,
  //   access: access.user
  // },
  // {
  //   name: 'promorecord',
  //   url: '/sponsorship/:id',
  //   component: CampaignDetailsComponent,
  //   resolve: [{
  //     token: 'promo',
  //     deps: [Transition, PromoService],
  //     resolveFn: (trans: Transition, promoService: PromoService) => promoService.get(trans.params()['id']).toPromise()
  //   }],
  //   access: access.user
  // },
  // { 
  //   name: 'promonew',
  //   url: '/sponsorship/new/promo',
  //   component: CampaignDetailsComponent,

  //   resolve: [{
  //     token: 'promo',
  //     resolveFn: () => {
  //       let promo: any = {};
  //       promo.campaigns = []; 
  //       promo.scheduled = []; 
  //       promo.contact = {};
  //       return promo;
  //     }
  //   }],
  //   access: access.user
  // },
  // { name: 'temporary',
  //   url: '/announcers/temporary',
  //     component: AnnouncersTemporaryComponent,
  //     access: access.user,
  //     params: {
  //       id: { squash: true, value: null },
  //     },
  // },

  // {
  //   name: 'programs',
  //   url: '/announcers/programs',
  //   component: AnnouncerProgramsComponent,
  //   access: access.user,
  //   params: {
  //     id: { squash: true, value: null },
  //   },
  // },

  // { name: 'zed-digital',
  //   url: '/zed-digital/:id?',
  //   component: ZedDigitalComponent,
  //   access: access.user,
  //   params: {
  //     id: { squash: true, value: null },
  //   },
  // },

  // {
  //   name: 'zedletter',
  //   url: '/zedletter',
  //   component: ZedletterComponent,
  //   access: access.user
  // },

  // { name: 'default',
  //   url: '/',
  //   component: HomeComponent,
  //   access: access.user
  // },

  // { 
  //   name: 'contacts',
  //   url: '/contacts/:id',
  //   component: ContactDetailsComponent,

  //   resolve: [{
  //     token: 'contact',
  //     deps: [Transition, ContactService],
  //     resolveFn: (trans: Transition, contactService: ContactService) => { 
  //       let id = trans.params()['id'];
  //       if (id === 'search' || id === 'new') {
  //         return {}
  //       }
  //       else {
  //         return contactService.get(id).toPromise();
  //       }
  //     }
  //   }],
  //   access: access.user
  // },
  // {
  //   name: 'volunteers', 
  //   url: '/volunteers/:id',
  //   component: VolunteerDetailsComponent,
  //   resolve: [{
  //     token: 'volunteer',
  //     deps: [Transition, VolunteerService],
  //     resolveFn: (trans: Transition, volunteerService: VolunteerService) => { 
  //       let id = trans.params()['id'];
  //       if (id === 'search' || id === 'new') {
  //         let volunteer:any = {};
  //         volunteer.zedder = {}
  //         return volunteer;
  //       }
  //       else {
  //         return volunteerService.get(id).toPromise();
  //       }
  //     }
  //   }],
  //   access: access.admin
  // },

  // {
  //  name: 'subscribers', 
  //   url: '/subscribers/:id',
  //   component: SubscriberDetailsComponent,
  //   resolve: [{
  //     token: 'subscriber',
  //     deps: [Transition, SubService],
  //     resolveFn: (trans: Transition, subService: SubService) => { 
  //       let id = trans.params()['id'];
  //       if (id === 'search' || id === 'new') {
  //         let subscriber:any = {};
  //         subscriber.zedder = {}
  //         return subscriber;
  //       }
  //       else {
  //         return subService.get(id).toPromise();
  //       }
  //     }
  //   }],
  //   access: access.user,
  // },

  // {
  //   name: 'orders',
  //   url: '/orders',
  //   component: OrdersComponent,
  //   access: access.user
  // },


  // { 
  //   name: 'orders.detail',
  //   url: '/:id',
  //   component: OrderDetailsComponent,
  //   access: access.user,
  //   resolve: [{
  //     token: 'order',
  //     deps: [Transition, OrdersService],
  //     resolveFn: (trans: Transition, ordersService: OrdersService) => { 
  //       let id = trans.params()['id'];
  //       if (id === 'search' || id === 'new') {
  //         let order:any = {};
  //         return order;
  //       }
  //       else {
  //         return ordersService.get(id).toPromise();
  //       }
  //     }
  //   }],
  // },

  // { 
  //   name:'releases.artist',
  //   url: '/artists/:artist?',
  //   component: ReleasesArtistsComponent,
  //   params: {
  //     artist: { squash: true, value: null },
  //   },
  //   resolve: {
  //     releases : function() {
  //       return [];
  //     }
  //   },
  //   access: access.public
  // },
  // {
  //   name: 'releases.details',
  //   url: '/:id',
  //   component: ReleasesDetailsComponent,
  //   resolve: [{
  //     token: 'release',
  //     deps: [Transition, ReleaseService],
  //     resolveFn: (trans: Transition, releaseService: ReleaseService) => { 
  //       let id = trans.params()['id'];
  //       if (id === 'search' || id === 'new') {
  //         let release:any = {};
  //         return release;
  //       }
  //       else {
  //         return releaseService.get(id).toPromise();
  //       }
  //     }
  //   }],
  //   access: access.public
  // },
  // {
  //   name: 'releases.archive',
  //   url: '/archive/:month/:endMonth',
  //   component: ReleasesArchiveComponent,
  //   params: {
  //     month: { squash: true, value: null },
  //     endMonth: { squash: true, value: null },
  //   },
  //   resolve: {
  //     releases : function() {
  //       return [];
  //     }
  //   },
  //   access: access.public
  // },
  // {
  //   name: 'releases.browse',
  //   url: '/browse/:month/:endMonth',
  //   component: ReleasesArchiveComponent,
  //   params: {
  //     month: { squash: true, value: null },
  //     endMonth: { squash: true, value: null },
  //   },
  //   resolve: {
  //     releases : function() {
  //       return [];
  //     }
  //   },
  //   access: access.public
  // }

] as unknown as Ng2StateDeclaration[];
