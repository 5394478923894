import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { environment } from 'src/environments/environment';
import { AnyMxRecord } from 'dns';
import { ReleasesBaseComponent } from '../releases-archive/releases.base.component';
import { SessionService } from '../session.service';
import { PlaylistService } from '../playlist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { any } from 'underscore';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { StudioService } from '../studio.service';
import { playlistToM3U, secondsToHMS } from '../helpers';
import { SocketService } from '../socket.service';
import { MatDialog } from '@angular/material/dialog';
import { AnnouncerPlaylistDialogComponent } from '../announcer-playlist-dialog/announcer-playlist-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

import * as moment from 'moment';

class MyDataSource extends DataSource<string | undefined> {
  private _length = 100000;
  private _pageSize = 100;
  private _cachedData = Array.from<string>({length: this._length});
  private _fetchedPages = new Set<number>();
  private readonly _dataStream = new BehaviorSubject<(any | undefined)[]>(this._cachedData);
  private readonly _subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private id: any,
    private mo: any,
    private length: number
  ) {
    super();
    this._length = this.length;
    this._cachedData = Array.from<string>({length: this.length});
    
  }
  connect(collectionViewer: CollectionViewer): Observable<(string | undefined)[]> {
    this._subscription.add(
      collectionViewer.viewChange.subscribe(range => {
        const startPage = this._getPageForIndex(range.start);
        const endPage = this._getPageForIndex(range.end - 1);
        for (let i = startPage; i <= endPage; i++) {
          this._fetchPage(i);
        }
      }),
    );
    return this._dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize);
  }

  private _fetchPage(page: number) {
    if (this._fetchedPages.has(page)) {
      return;
    }
    this._fetchedPages.add(page);

    // Use `setTimeout` to simulate fetching data from server.
    this.http.post<any>( environment.musicDBapiSrc + '/playlist/' + this.id, { page: page } ).subscribe( playlist => {
      playlist.totalLength = 0;
      let length = playlist.tracks.map( i => ( i.pivot.details && Number(i.pivot.details.length) ) || Number(i.splitlength))
      let accumLength = 0;


      playlist.tracks.forEach(track => {
        track.accumLength = accumLength;
        let accumTime = moment(this.mo).add(accumLength, 'seconds');
        track.accumTime = accumTime.format('hh:mm:ss');
        accumLength =( ( track.pivot.details && Number(track.pivot.details.length) ) || Number(track.splitlength) ) + accumLength;
      });
      
      playlist.totalLength =  playlist.total_length  ? Number(playlist.total_length)  : length.length ? length.reduce((a,b) => Number(a) + Number(b)): 0;



      // this.selectedPlaylist = playlist;

      // this.selectedPlaylist.tracks = [...playlist.tracks]

      this._cachedData.splice(
        page * this._pageSize,
        this._pageSize,
        ...playlist.tracks
        ,
      );
      this._dataStream.next(this._cachedData);
    });
    
    // setTimeout(
    //   () => {
    //     this._cachedData.splice(
    //       page * this._pageSize,
    //       this._pageSize,
    //       ...Array.from({length: this._pageSize}).map(
    //         (_, i) => `Item #${page * this._pageSize + i}`,
    //       ),
    //     );
    //     this._dataStream.next(this._cachedData);
    //   },
    //   Math.random() * 1000 + 200,
    // );
  }
}

@Component({
  selector: 'app-announcer-playlists',
  templateUrl: './announcer-playlists.component.html',
  styleUrls: ['./announcer-playlists.component.scss']
})
export class AnnouncerPlaylistsComponent extends ReleasesBaseComponent {
  constructor( 
    router: Router,
    location: Location,
    http: HttpClient,
		// @Inject('$rootScope') $rootScope: any,
		PlaylistService: PlaylistService,
		SessionService: SessionService,
    private StudioService: StudioService,
		private socket: SocketService,
    private AuthenticationService: AuthenticationService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    super(router, location, http, PlaylistService, SessionService);

    this.onAir = this.StudioService.getOnAir()
    this.studio = this.StudioService.getStudio()
    this.StudioService.onMessage().subscribe( data => { 
      console.log(data);
      if(data.onAir)
        this.onAir = data.onAir; 
      if (data.studio)
        this.studio = data.studio;
    });
  }


  dataSource;
  timeFormat = 'track';
  zedderId; 
  user;
  mo;
  id;

  onAir: boolean = false; 
  studio: number | null = null; 

  updateImages; 

  playlists: any[] = [];
  archive: any[] = [];

  showArchive = false;
  
  musicDBapiSrc = environment.musicDBapiSrc;
  selectedPlaylist: any;

  ngOnInit(){
    this.zedderId = this.AuthenticationService.getUser().details?.id;
    this.user = this.AuthenticationService.getUser();
    this.getPlaylistsByUser()
    let announcerHour = (this.user?.details?.announcer && this.user?.details?.announcer.length) && this.user?.details?.announcer[0].hour;
    let backupHour = (this.user?.details?.backup_announcer && this.user?.details?.backup_announcer.length) && this.user?.details?.backup_announcer[0].hour;

    let showStart = announcerHour ? announcerHour : backupHour ? backupHour : 15;
    console.log(showStart)
    this.mo = moment().hour(showStart).minute(0).second(0);

    console.log(this.mo)

    this.route.params.subscribe(({ playlist }) => {
      this.id = this.route.snapshot.paramMap.get('id');
      console.log(this.id);
      if(this.id)
        this.getPlaylistsByID();
    });

    this.dataSource = new MyDataSource(this.http, this.id, this.mo, 1000 );
  }

  getPlaylistsByID() { 
    this.http.get<any>( environment.musicDBapiSrc + '/playlist/' + this.id ).subscribe( playlist => {
      playlist.totalLength = 0;
      let length = playlist.tracks.map( i => ( i.pivot.details && Number(i.pivot.details.length) ) || Number(i.splitlength))
      let accumLength = 0;


      playlist.tracks.forEach(track => {
        track.accumLength = accumLength;
        let accumTime = moment(this.mo).add(accumLength, 'seconds');
        track.accumTime = accumTime.format('hh:mm:ss');
        accumLength =( ( track.pivot.details && Number(track.pivot.details.length) ) || Number(track.splitlength) ) + accumLength;
      });
      
      playlist.totalLength =  playlist.total_length ? Number(playlist.total_length) : length.length ? length.reduce((a,b) => Number(a) + Number(b)): 0;

      this.selectedPlaylist = playlist;

      this.selectedPlaylist.tracks = [...playlist.tracks]

      this.dataSource = new MyDataSource(this.http, this.id, this.mo, playlist.tracks_count );

    });
  }
  

  getPlaylistsByUser() {
    if(this.zedderId) {
      this.http.get<any[]>( environment.musicDBapiSrc + '/playlists-by-user/' + this.zedderId ).subscribe( data => {
        // data.forEach( playlist => { 
        //   playlist.totalLength = 0;
        //   let length = playlist.tracks.map( i => ( i.pivot.details && Number(i.pivot.details.length) ) || Number(i.splitlength))
        //   // let length = playlist.tracks.map( i => Number(i.splitlength))
        //   let accumLength = 0;


        //   playlist.tracks.forEach(track => {
        //     track.accumLength = accumLength;
        //     let accumTime = moment(this.mo).add(accumLength, 'seconds');
        //     track.accumTime = accumTime.format('hh:mm:ss');
        //     accumLength = Number(track.splitlength) + accumLength;
        //   });
          
        //   playlist.totalLength =  length.length ? length.reduce((a,b) => Number(a) + Number(b)): 0;
        // } )

        this.playlists = data.filter(i => !i.archive);
        this.archive = data.filter(i => i.archive);

        // if(this.selectedPlaylist) {
        //   this.selectedPlaylist = this.playlists.find( i => i.id === this.selectedPlaylist.id);

        // }
      })
    }
  }
  playPlaylist(playlist) {

    this.PlaylistService.prepForBroadcast({ playlist: playlist });
  }

  editPlaylist(playlist) {
    const dialogRef = this.dialog.open(AnnouncerPlaylistDialogComponent, {
      data: {
        playlist: playlist
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result !== 'abort') {
        console.log(result);
        this.getPlaylistsByUser();
        this.updateImages = new Date().getTime();
      }
    });
  }

  deletePlaylist(playlist) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data:{ 
        title: 'Delete Playlist',
        message: 'This will permanently delete playlist and all tracks associated with it!',
        confirm: 'Delete',
        abort: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
			console.log('deleting sponsorship campaign', playlist.id);
      if(result === 'confirm') {
        this.http.post(environment.musicDBapiSrc + '/delete-playlist', { id: playlist.id}).subscribe( data => {
          alert('Playlist Deleted');
          this.getPlaylistsByUser();
        });
      }
      else {
        console.log('Cancelled Deletion');
      }
		})
  }

  deleteTrack = (track, key) => {
    this.selectedPlaylist.tracks.splice(key, 1);

    this.http.post(environment.musicDBapiSrc + '/delete-playlist-track', track.pivot).subscribe( data => {


    });
	}


  attachSpots = (tracks) => {
    let spots = tracks.map( i => { i.fullpath = i.pivot.file ? 'T:'+i.pivot.file : i.fullpath; return { length: i.splitlength, title: i.title, file: i.fullpath.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:') }})
    let data = playlistToM3U(spots);
    let studio = 'studio' + this.studio;
    this.http.post(environment.musicDBapiSrc + '/save-playlist', { file: studio, destination: '/Tech', playlist: data, share: 'temporary'}).subscribe( data => {
      this.socket.send({ appendTrack: { id: String(this.AuthenticationService.getUser().details.id).padStart(5, '0'), studio: this.studio, file: 'T:/Tech/Playlists/studio'+ this.studio + '.m3u' } });
    })
  }
  
  savePlaylistToTemp(playlist) {

		var data = "#EXTM3U \r\n";
		for (var i = 0; i < playlist.tracks.length; i++) {
			var fav = playlist.tracks[i];
			var text = fav.pivot.file ? 'T:'+fav.pivot.file : fav.fullpath;
			var link;
      link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')

			// if (navigator.platform.search('Win') !== -1) {
			// 	console.log('win');
			// 	link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')
			// }

			// if (navigator.platform.search('Mac') !== -1) {
			// 	console.log('mac');
			// 	link = text.replace('/mnt/fela', '/Volumes/audio')
			// }

			// if (navigator.platform.search('Linux') !== -1) {
			// 	console.log('lin');
			// 	console.log(text);
			// 	link = text;

			// }

			data += "#EXTINF:" + (fav.pivot.details?.length || fav.length ) + ", " + (fav.artist && fav.artist.name ? fav.artist.name : fav.artist)  + " - " + fav.title +"\r\n";
			data += link + "\r\n";
		}

    this.http.post(environment.musicDBapiSrc + '/save-playlist', { file: playlist.name, destination: '/The Brown Couch', playlist: data, share: 'temporary'}).subscribe( data => {

    });
  }
  
  downloadPlaylist(playlist) {

		var data = "#EXTM3U \r\n";
		for (var i = 0; i < playlist.tracks.length; i++) {
			var fav = playlist.tracks[i];
			var text = fav.pivot.file ? 'T:'+fav.pivot.file : fav.fullpath;
			var link;
      link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')

			// if (navigator.platform.search('Win') !== -1) {
			// 	console.log('win');
			// 	link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')
			// }

			// if (navigator.platform.search('Mac') !== -1) {
			// 	console.log('mac');
			// 	link = text.replace('/mnt/fela', '/Volumes/audio')
			// }

			// if (navigator.platform.search('Linux') !== -1) {
			// 	console.log('lin');
			// 	console.log(text);
			// 	link = text;

			// }

			data += "#EXTINF:" + fav.title_id + ", " + (fav.artist && fav.artist.name ? fav.artist.name : fav.artist)  + " - " + fav.title +"\r\n";
			data += link + "\r\n";
		}

    const blob = new File([data], playlist.name + '.m3u', { type: 'audio/x-mpegurl' });
		const url = window.URL.createObjectURL(blob);
		window.open(url);

		// var blob = new Blob([data], { type: 'text/plain' })

		// var url = window.URL || window.webkitURL;

		// console.log(url)
		// this.fileUrl = url.createObjectURL(blob);
		// console.log(this.fileUrl)
  }
  
  queuePlaylist(playlist) {
    this.PlaylistService.prepForBroadcast({ playlist: playlist });
  }

  selectPlaylist(playlist) {
    this.selectedPlaylist = playlist;

  }

  sendTrackTop(key) {
    this.selectedPlaylist.tracks.unshift(this.selectedPlaylist.tracks.splice(key, 1)[0]);
    this.http.post(environment.musicDBapiSrc + '/reorder-playlist-tracks', { playlist_id: this.selectedPlaylist.id,  tracks: this.selectedPlaylist.tracks.map( i => ({ title_id:  i.title_id, pivot: i.pivot }) ) }).subscribe( data => {

    });
  }

  sendTrackBottom(key) {
    this.selectedPlaylist.tracks.push(this.selectedPlaylist.tracks.splice(key, 1)[0]);
    this.http.post(environment.musicDBapiSrc + '/reorder-playlist-tracks', { playlist_id: this.selectedPlaylist.id,  tracks: this.selectedPlaylist.tracks.map( i => ({ title_id:  i.title_id, pivot: i.pivot }) ) }).subscribe( data => {

    });
  } 

  drop(event: any ) {
    moveItemInArray(this.selectedPlaylist.tracks, event.previousIndex, event.currentIndex);
    this.selectedPlaylist.tracks = [...this.selectedPlaylist.tracks]

    this.http.post(environment.musicDBapiSrc + '/reorder-playlist-tracks', { playlist_id: this.selectedPlaylist.id,  tracks: this.selectedPlaylist.tracks.map( i => ({ title_id:  i.title_id, pivot: i.pivot }) ) }).subscribe( data => {

    });

    console.log(this.selectedPlaylist.tracks);
    // this.SessionService.set('favourites', JSON.stringify(this.favourites) );
    // this.FavouriteService.prepForBroadcast('update');
  }

  toggleTime() {
    this.timeFormat = this.timeFormat === 'track' ? 'accumLength' : this.timeFormat === 'accumLength' ? 'accumTime' : 'track';
  }
}
