<div style="display: flex; justify-content: start; align-items: start; gap: 16px; padding: 16px; overflow-y: hidden; overflow-x: scroll; min-height: 200px;">
	<div *ngFor="let playlist of playlists" [routerLink]="'/playlists/' + playlist.id">
		<div style="width: 150px; height: 150px; background-size: cover; background-position: center; background-color: rgb(30, 30, 30); border: solid 1px black; border-radius: 8px;" [style.backgroundImage]="'url('+ musicDBapiSrc + '/coverArt/150/' + playlist.id  + '/playlist' + ( updateImages ? '?t=' + updateImages : '' )  + ')'" class="cover"  >
			<div style="position: absolute; top: 4px; right: 4px;" *ngIf="playlist.pin"><mat-icon style="font-size: 14px;
				width: 14px;
				height: 14px;
				transform: rotate(45deg);
			">push_pin</mat-icon></div>

			<div style="position: absolute; bottom: 4px; right: 4px; height: 14px;" *ngIf="playlist.contributors.length"><span style="line-height: 16px;
				font-size: 12px;
				vertical-align: top;
				height: 16px;">{{playlist.contributors.length}}</span><mat-icon style="font-size: 14px;
				width: 14px;
				height: 14px;
			">face</mat-icon></div>
			<div class="bg">
				<mat-icon class="favourite">star_border</mat-icon>
				<mat-icon class="play" (click)="playPlaylist(playlist.tracks)" >play_circle_filled</mat-icon>
				<mat-icon  mat-icon-button (click)="$event.stopPropagation(); $event.preventDefault()" [matMenuTriggerFor]="coverMenu" class="more" aria-label="more">more_horiz</mat-icon>
				<mat-menu #coverMenu="matMenu">
					<button mat-menu-item (click)="queuePlaylist(playlist)"> 
							Add to queue
					</button>
				</mat-menu>
			</div>
		</div>
		<div>{{playlist.name}}</div>
	</div>
</div>

<div *ngIf="archive.length" (click)="showArchive = !showArchive" style="text-align: right;
padding: 4px 16px 20px 16px;
font-weight: 400;
text-decoration: underline;
"><span [style.background]="showArchive ? '#c1c1c1' : '#212121'" [style.color]="showArchive ? '#212121' : '#c1c1c1'" style="
  padding: 4px; cursor: pointer">{{showArchive ? 'HIDE' : 'SHOW'}} ARCHIVE</span>
</div>

<div style="display: flex; justify-content: start; align-items: center; gap: 16px; padding: 16px; overflow-y: scroll;" *ngIf="showArchive">
	<div *ngFor="let playlist of archive" (click)="selectPlaylist(playlist)" [routerLink]="'/playlists/' + playlist.id">
		<div style="width: 150px; height: 150px; background-size: cover; background-position: center; background-color: rgb(30, 30, 30); border: solid 1px black; border-radius: 8px;" [style.backgroundImage]="'url('+ musicDBapiSrc + '/coverArt/150/' + playlist.id  + '/playlist' + ( updateImages ? '?t=' + updateImages : '' )  + ')'" class="cover"  >
			<div style="position: absolute; top: 4px; right: 4px;" *ngIf="playlist.pin"><mat-icon style="font-size: 14px;
				width: 14px;
				height: 14px;
				transform: rotate(45deg);
			">push_pin</mat-icon></div>

			<div style="position: absolute; bottom: 4px; right: 4px; height: 14px;" *ngIf="playlist.contributors.length"><span style="line-height: 16px;
				font-size: 12px;
				vertical-align: top;
				height: 16px;">{{playlist.contributors.length}}</span><mat-icon style="font-size: 14px;
				width: 14px;
				height: 14px;
			">face</mat-icon></div>
			<div class="bg">
				<mat-icon class="favourite">star_border</mat-icon>
				<mat-icon class="play" (click)="playPlaylist(playlist.tracks)" >play_circle_filled</mat-icon>
				<mat-icon  mat-icon-button (click)="$event.stopPropagation(); $event.preventDefault()" [matMenuTriggerFor]="coverMenu" class="more" aria-label="more">more_horiz</mat-icon>
				<mat-menu #coverMenu="matMenu">
					<button mat-menu-item (click)="queuePlaylist(playlist)"> 
							Add to queue
					</button>
				</mat-menu>
			</div>
		</div>
		<div>{{playlist.name}}</div>
	</div>
</div>

<div *ngIf="selectedPlaylist" style="flex: 1; display: flex; flex-flow: column;">
	<mat-toolbar style="position: sticky; top: 0; z-index: 150;">
		<mat-toolbar-row style="gap: 16px;">
			<div style="width: 48px; height: 48px; background-size: cover; background-position: center; background-color: #1e1e1e; border: solid 1px black;" [style.backgroundImage]="'url('+ musicDBapiSrc + '/coverArt/150/' + selectedPlaylist.id  + '/playlist' + ( updateImages ? '?t=' + updateImages : '' )  +')'" ></div>
			<h3 style="flex: 1">{{selectedPlaylist.name}}</h3>
			<span style="font-size: 60%; font-weight: 100;">{{ selectedPlaylist.tracks_count }} {{ selectedPlaylist.tracks_count === 1 ? 'track' : 'tracks' }} / {{ selectedPlaylist.totalLength | hms}}</span>
			<button mat-icon-button (click)="playPlaylist(selectedPlaylist.tracks)" matTooltip="Play Playlist" aria-label="play playlist">
				<mat-icon>play_arrow</mat-icon>
			</button>
			<button  *ngIf="onAir" (click)="attachSpots(selectedPlaylist.tracks)" matTooltip="Send Playlist to Mairlist" matTooltipPosition="below" style="color: #fa8817" mat-icon-button>
				<mat-icon svgIcon="mairlist"></mat-icon>
			</button>
			<button mat-icon-button (click)="savePlaylistToTemp(selectedPlaylist)" matTooltip="Save Playlist To Temporary" aria-label="save playlist to temporary">
				<mat-icon>bookmark</mat-icon>
			</button>
			<button mat-icon-button (click)="downloadPlaylist(selectedPlaylist)" matTooltip="Download Playlist" aria-label="download as playlist">
				<mat-icon>save_alt</mat-icon>
			</button>
			<button mat-icon-button (click)="editPlaylist(selectedPlaylist)" matTooltip="Edit Playlist Details" aria-label="edit playlist details">
				<mat-icon>edit</mat-icon>
			</button>
			<button mat-icon-button (click)="deletePlaylist(selectedPlaylist)" matTooltip="Delete Playlist" aria-label="Delete playlist">
				<mat-icon>delete</mat-icon>
			</button>
		</mat-toolbar-row>
	</mat-toolbar>

<ng-container *ngIf="selectedPlaylist.tracks_count <= 100">

	<div id="playlist-scroll" cdkDropList (cdkDropListDropped)="drop($event)" style="display: flex; flex-direction: column; margin: 8px;">
		<div *ngFor="let track of selectedPlaylist.tracks; index as key" cdkDrag class="track">
			<div style="display: flex; flex-direction: row; padding: 8px; gap: 8px; text-align: center; line-height: 24px;">
				<mat-icon class="mairlist" *ngIf="onAir" (click)="attachSpots([track])" matTooltip="Send Track to Mairlist" style=" color: #fa8817; font-size: 14px; text-align: center; line-height: 24px;" svgIcon="mairlist"></mat-icon>
				<div style="width: 48px; max-width: 48px; min-width: 48px; text-align: center; display: flex; justify-content: space-between;">
					<div class="top" (click)="sendTrackTop(key)" matTooltip="Send to Top">▲</div>
					<div>{{key + 1}}</div>
					<div class="bottom" (click)="sendTrackBottom(key)" matTooltip="Send to Bottom">▼</div>
				</div>

				<ng-container *ngIf="track.title_id !== -1">
					<div style="text-align: left;">
						<a class="track-text" (click)="play(track, $event)">
							<span>{{track.artist && track.artist.name ? track.artist.name : track.artist }}</span> <span>{{track.title}}</span>
						</a> 
					</div>

					<div style="flex: 1"></div>
					<div style="display: flex; flex-direction: row; gap: 8px; text-align: right;">
						<span style="font-style: italic;">
							<a [routerLink]="'/releases/' + track.library_no">
								{{track.album && track.album.name ? track.album.name : track.album }}
							</a>
						</span> 
						<span style="font-weight: 100;">{{track.year}}</span> 
						<span class="time" *ngIf="timeFormat === 'track'" (click)="toggleTime()">{{ track.length | hms }}</span> <span class="time" *ngIf="timeFormat === 'accumLength'" (click)="toggleTime()">{{ track.accumLength | hms }}</span> 
						<span class="time"  *ngIf="timeFormat === 'accumTime'" (click)="toggleTime()">{{ track.accumTime }}</span> 
					</div>
				</ng-container>

				<ng-container *ngIf="track.title_id === -1">
					<div>
						<a class="track-text" (click)="playTemp({filename: track.pivot.file, share: 'temporary' })">
							<span>{{ track.pivot.file }}</span>
						</a> 
					</div>
					<div style="flex: 1"></div>
					<div style="display: flex; flex-direction: row; gap: 8px;">
						<span style="font-style: italic;">
							<a [routerLink]="'/releases/' + track.library_no">
								{{track.album && track.album.name ? track.album.name : track.album }}
							</a>
						</span> 
						<span style="font-weight: 100;">{{track.year}}</span> 
						<span class="time" *ngIf="timeFormat === 'track'" (click)="toggleTime()">{{ track.pivot.details?.length | hms }}</span> <span class="time" *ngIf="timeFormat === 'accumLength'" (click)="toggleTime()">{{ track.accumLength | hms }}</span> 
						<span class="time"  *ngIf="timeFormat === 'accumTime'" (click)="toggleTime()">{{ track.accumTime }}</span> 
					</div>
				</ng-container>

				<mat-icon class="delete" (click)="deleteTrack(track, key)" style="font-size: 14px; text-align: center; line-height: 24px;" matTooltip="Delete Track" aria-label="delete">
					delete
				</mat-icon>
			</div>
		</div>
	</div>
	<!-- <app-album-track-list [tracks]="selectedPlaylist.tracks" [favourite]="favourite" [play]="play" /> -->
</ng-container>

<ng-container *ngIf="selectedPlaylist.tracks_count > 100">


	<cdk-virtual-scroll-viewport id="playlist-scroll" cdkDropList (cdkDropListDropped)="drop($event)"  itemSize="40"  style="display: flex; flex-direction: column; margin: 8px; flex: 1;">
		<div *cdkVirtualFor="let track of dataSource; let key = index"  class="track">
			<div style="display: flex; flex-direction: row; padding: 8px; gap: 8px; text-align: center; line-height: 24px;">
				<mat-icon class="mairlist" *ngIf="onAir" (click)="attachSpots([track])" matTooltip="Send Track to Mairlist" style=" color: #fa8817; font-size: 14px; text-align: center; line-height: 24px;" svgIcon="mairlist"></mat-icon>
				<div style="width: 48px; max-width: 48px; min-width: 48px; text-align: center; display: flex; justify-content: space-between;">
					<!-- <div class="top" (click)="sendTrackTop(key)" matTooltip="Send to Top">▲</div> -->
					<div>{{key + 1}}</div>
					<!-- <div class="bottom" (click)="sendTrackBottom(key)" matTooltip="Send to Bottom">▼</div> -->
				</div>

				<ng-container *ngIf="track.title_id !== -1">
					<div style="text-align: left;">
						<a class="track-text" (click)="play(track, $event)">
							<span>{{track.artist && track.artist.name ? track.artist.name : track.artist }}</span> <span>{{track.title}}</span>
						</a> 
					</div>

					<div style="flex: 1"></div>
					<div style="display: flex; flex-direction: row; gap: 8px; text-align: right;">
						<span style="font-style: italic;">
							<a [routerLink]="'/releases/' + track.library_no">
								{{track.album && track.album.name ? track.album.name : track.album }}
							</a>
						</span> 
						<span style="font-weight: 100;">{{track.year}}</span> 
						<span class="time" *ngIf="timeFormat === 'track'" (click)="toggleTime()">{{ track.length | hms }}</span> <span class="time" *ngIf="timeFormat === 'accumLength'" (click)="toggleTime()">{{ track.accumLength | hms }}</span> 
						<span class="time"  *ngIf="timeFormat === 'accumTime'" (click)="toggleTime()">{{ track.accumTime }}</span> 
					</div>
				</ng-container>

				<ng-container *ngIf="track.title_id === -1">
					<div>
						<a class="track-text" (click)="playTemp({filename: track.pivot.file, share: 'temporary' })">
							<span>{{ track.pivot.file }}</span>
						</a> 
					</div>
					<div style="flex: 1"></div>
					<div style="display: flex; flex-direction: row; gap: 8px;">
						<span style="font-style: italic;">
							<a [routerLink]="'/releases/' + track.library_no">
								{{track.album && track.album.name ? track.album.name : track.album }}
							</a>
						</span> 
						<span style="font-weight: 100;">{{track.year}}</span> 
						<span class="time" *ngIf="timeFormat === 'track'" (click)="toggleTime()">{{ track.pivot.details?.length | hms }}</span> <span class="time" *ngIf="timeFormat === 'accumLength'" (click)="toggleTime()">{{ track.accumLength | hms }}</span> 
						<span class="time"  *ngIf="timeFormat === 'accumTime'" (click)="toggleTime()">{{ track.accumTime }}</span> 
					</div>
				</ng-container>

				<!-- <mat-icon class="delete" (click)="deleteTrack(track, key)" style="font-size: 14px; text-align: center; line-height: 24px;" matTooltip="Delete Track" aria-label="delete">
					delete
				</mat-icon> -->
			</div>
		</div>
	</cdk-virtual-scroll-viewport>
</ng-container>

</div>