import { Component, Input, Inject } from '@angular/core';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-m3u-playlist',
  templateUrl: './m3u-playlist.component.html',
  styleUrls: ['./m3u-playlist.component.scss']
})
export class M3uPlaylistComponent {
  @Input('tracks') public tracks;
  @Input('options') public options?;
  constructor( 
    private http: HttpClient,
    // @Inject('$rootScope') private $rootScope,
  ) {

  }
  stationPlaylist = false;
  totalLength: number = 0;

  ngOnChanges(){
    this.calculateLengths()

    if(this.options && this.options.zdPlaylist) {
      this.stationPlaylist = true;
    }
  }

  deleteTrack(track, key) {
    console.log(track, key)
    this.tracks.splice(key, 1);
    this.calculateLengths()
  }

  calculateLengths(){
    let lengths = this.tracks.map( i => i.length)
    this.totalLength = lengths.length ? lengths.reduce((a,b) => Number(a)+Number(b)) : 0;
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(event)
    if (event.previousContainer === event.container) {
      moveItemInArray(this.tracks, event.previousIndex, event.currentIndex);
    }
    else {
      const dragged: any = event.previousContainer.data[event.previousIndex];
      console.log(event.previousContainer.data[event.previousIndex])

      this.tracks.splice(event.currentIndex, 0, {
        file: dragged.filename,
        artist: '...',
        title: '...',
        length: 0,
        category: '<Track>'
      });

      let params: any = { file: dragged.folder + '/' + dragged.filename }
      
      if (this.options && this.options.share &&  this.options.share === 'temporary') {
        params = { ...params, share: 'temporary' }
      }
      this.http.post<any>(environment.musicDBapiSrc + '/info-metadata', params ).subscribe( data => { 
        let track = data;
        let felatrack = track.path + '/' + track.file;
        let item = {
          file: felatrack.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:'),
          artist: track.artist,
          title: track.title,
          length: track.splitlength,
          category: '<Track>'
        }
        // this.tracks.splice(event.currentIndex, 0, item);
        this.tracks[event.currentIndex] = item;
        let lengths = this.tracks.map( i => i.length)
        this.totalLength = lengths.length ? lengths.reduce((a,b) => Number(a)+Number(b)) : 0;
      });
    }
  }

  downloadPlaylist() {
    console.log(this.tracks)
    const splDefault = '|0|00|00|0|-1|0|0|0|0|0||-1|0'

    let title = this.options && this.options.zdPlaylist ? this.options.zdPlaylist.format('MMMDD-HH') + '.M3U' : 'playlist.m3u';

		var data = "#EXTM3U\r\n";
		for (var i = 0; i < this.tracks.length; i++) {
			const track = this.tracks[i];
			var link = track.file;

      let splInfo = ''
      if (track.splParts) {
        splInfo = track.splParts;
      } 
      else {
        splInfo = [ track.artist, track.title, track.category].join('|') + splDefault;
        splInfo = "|" + splInfo + "|";
      }
			data += "#EXTINF:" + track.length + "," + splInfo + "\r\n";
			data += link + "\r\n";
		}

		var blob = new File([data], title, { type: 'text/plain' })

		var url = window.URL || window.webkitURL;

		console.log(url)
		let fileUrl = url.createObjectURL(blob);
		window.open(fileUrl);

		console.log(fileUrl)
  }

}
