<mat-toolbar>
  <mat-toolbar-row style="justify-content: space-between;">
    <h2>Subscribers Report</h2>
  </mat-toolbar-row>
</mat-toolbar>
<section style="display: flex; flex-direction: row; background: #2e2e2e; padding: 16px; gap: 16px; " class="mat-typography">
  <div style="width: 360px; height: auto; display: flex; flex-direction: column; gap: 16px;" >
    <mat-card [formGroup]="subFilter">
      <mat-card-header>
        <mat-card-title>
          Filter Subscribers
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field class="full-width">
          <mat-label>Sub Types</mat-label>
          <mat-chip-grid #chipGrid aria-label="Subscription selection">
            <mat-chip-row *ngFor="let subscription of subFilter.get('subtype')!.value" 
            (removed)="remove(subscription)">
              {{subscription.subtypecode}}
              <button matChipRemove [attr.aria-label]="'remove ' + subscription">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Subscription type..." #subscriptionInput [formControl]="subscriptionCtrl"
            [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"/>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let subscription of filteredSubscriptions | async" [value]="subscription">
              {{subscription.subtypecode}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Suburb</mat-label>
          <input type="text"
                 placeholder="Search Suburbs"
                 aria-label="Suburb"
                 matInput
                 formControlName="suburb"
                 [matAutocomplete]="suburbComplete">
          <mat-autocomplete (optionSelected)="selectSuburb($event)" [displayWith]="displayFn" #suburbComplete="matAutocomplete">
            <mat-option  *ngFor="let option of suburbFilteredOptions | async" [value]="option">
              {{option.suburb}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Postcode</mat-label>
          <input matInput placeholder="Postcode" formControlName="postcode">
        </mat-form-field>

        <mat-form-field class="full-width">
          <button (click)="resetExpiredRange()" matSuffix mat-icon-button><mat-icon>clear</mat-icon></button>
          <mat-label>Expired Period</mat-label>
          <mat-date-range-input [rangePicker]="expiredPicker">
            <input matStartDate #dateRangeStart formControlName="expiredStart" placeholder="Start date">
            <input matEndDate #dateRangeEnd formControlName="expiredEnd" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="expiredPicker"></mat-datepicker-toggle>
          <mat-date-range-picker style="width:316px" #expiredPicker></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field class="full-width">
          <button (click)="resetPaidRange()" matSuffix mat-icon-button><mat-icon>clear</mat-icon></button>
          <mat-label>Paid Period</mat-label>
          <mat-date-range-input [rangePicker]="paidPicker">
            <input matStartDate #dateRangeStart formControlName="paidStart" placeholder="Start date">
            <input matEndDate #dateRangeEnd formControlName="paidEnd" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="paidPicker"></mat-datepicker-toggle>
          <mat-date-range-picker style="width:316px" #paidPicker></mat-date-range-picker>
        </mat-form-field>

        <button mat-raised-button (click)="search()">
          Search
          <mat-progress-bar 
          *ngIf="fetchingSearch"
          class="button-progress"
          mode="indeterminate">
        </mat-progress-bar>
        </button>
      </mat-card-content>
    </mat-card>



    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Reports
        </mat-card-title>
      </mat-card-header>
      <mat-card-content style="flex-flow: wrap;
      gap: 8px;
      display: flex;">
        <button (click)="activeSubs()" mat-raised-button>Active Sub Totals</button>
        <button (click)="activeSubsList()" mat-raised-button>
          Active Subs List
          <mat-progress-bar 
            *ngIf="fetchingActiveSubList"
            class="button-progress"
            mode="indeterminate">
          </mat-progress-bar>
        </button>
        <button (click)="expiresToday()" mat-raised-button>Expires Today</button>
        <button (click)="expiresTwoWeeks()" mat-raised-button>Expiring 2 weeks</button>
        <button (click)="paidToday()" mat-raised-button>Paid Today</button>
        <button (click)="getEmailList()" mat-raised-button>Email List</button>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Prizes
        </mat-card-title>
      </mat-card-header>
      <mat-card-content style="display: flex; flex-direction: column; gap: 16px;">

        <div style="background-color: rgb(71, 71, 71); margin: 4px -8px; padding: 8px 8px;">
          <h3>Radiothon / April Atonement</h3>
          <mat-form-field class="full-width">
            <mat-label>Prize</mat-label>
            <mat-select [formControl]="prizeControl">
              <mat-option *ngFor="let prize of prizes" [value]="prize.radiothonprizeid">{{prize.radiothonprize}}</mat-option>
            </mat-select>
          </mat-form-field>

          <div style="display: flex; flex-flow: wrap; gap: 8px;">
            <button (click)="getSubsByPrize()" mat-raised-button>Contestants</button>
            <button (click)="drawPrize()" mat-raised-button>Draw Prize</button>
          </div>

          <div *ngIf="prizeWinner" style="padding: 16px 0;">
            <h4>The Winner</h4> 
            <a [href]="'/subscribers/' + prizeWinner.subid">{{prizeWinner.subfirstname}} {{prizeWinner.sublastname}} {{prizeWinner.subnumber}}</a>
          </div>
        </div>

        <div style="background-color: rgb(71, 71, 71); margin: 4px -8px; padding: 8px 8px;">
          <h3>Monthly Prize Draw</h3>
          <mat-form-field class="full-width">
            <mat-label>Prize Month</mat-label>
            <button style="--mdc-fab-container-color: transparent; box-shadow: none;" matSuffix class="month-button" mat-fab (click)="toggleHotbinOverlay()" cdkOverlayOrigin #monthPickerTrigger="cdkOverlayOrigin" aria-label="Show month calendar">
              <mat-icon>date_range</mat-icon>
            </button>
            
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="monthPickerTrigger"
              [cdkConnectedOverlayOpen]="isMonthPickerOpen"
              (overlayOutsideClick)="closeOverlay($event)"
            >
              <mat-card class="calendar-card">
                <app-month-calendar (dateChanged)="dateChanged($event)" [selectedDate]="selectedDate"/>
              </mat-card>
            </ng-template>
  
            <input matInput placeholder="Month" [formControl]="monthlyPrizeControl" value="">
          </mat-form-field>
          
          <div style="display: flex; flex-flow: wrap; gap: 8px;">
            <button (click)="checkMonthlyPrizeContestants()" mat-raised-button>Contestants</button>
            <button (click)="drawMonthlyPrize()" mat-raised-button>Draw Prize</button>
          </div>

          <div *ngIf="monthlyPrizeWinner" style="padding: 16px 0;">
            <h4>The Winner</h4> 
            <a [href]="'/subscribers/' + monthlyPrizeWinner.subid">{{monthlyPrizeWinner.subfirstname}} {{monthlyPrizeWinner.sublastname}} {{monthlyPrizeWinner.subnumber}}</a>
          </div>
        </div>

      </mat-card-content>

    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Graphs
        </mat-card-title>
      </mat-card-header>
      <mat-card-content style="display: flex; flex-direction: column; gap: 16px;">
        <div [formGroup]="lapsed" style="background-color: rgb(71, 71, 71); margin: 4px -8px;
  padding: 8px 8px;">
          <h3>Lapsed Subs</h3>
          <p style="font-style: italic;">Using the start and end dates and a lapsed period returns all subs who expired between the start and end dates, and of them who renewed or had a fresh subscription paid between this period or one year before the end date (whichever is larger)</p>
          <mat-form-field class="full-width">
            <mat-label>Period</mat-label>
            <mat-date-range-input [rangePicker]="lapsedPicker">
              <input matStartDate #dateRangeStart formControlName="startDate" placeholder="Start date">
              <input matEndDate #dateRangeEnd formControlName="endDate" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="lapsedPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #lapsedPicker></mat-date-range-picker>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Lapsed period</mat-label>
            <input matInput placeholder="Lapsed Period" formControlName="lapsed">
          </mat-form-field>

          <button mat-raised-button (click)="lapsedSearch()">
            Search
            <mat-progress-bar 
              *ngIf="fetchingLapsed"
              class="button-progress"
              mode="indeterminate">
            </mat-progress-bar>
          </button>

        </div>

        <div style="background-color: rgb(71, 71, 71); margin: 4px -8px; padding: 8px 8px;">
          <h3>5 Year Subscriptions</h3>
          <p style="font-style: italic;">Pulls subscription data of valid subs for each week (expiring one year after the sampled week) for a period of five years.</p>
          <button mat-raised-button (click)="fiveYearSubGrowth()">
            5 Year Subs
            <mat-progress-bar 
              *ngIf="fetchingFiveYear"
              class="button-progress"
              mode="indeterminate">
            </mat-progress-bar>
          </button>

          

        </div>

        <div style="background-color: rgb(71, 71, 71); margin: 4px -8px; padding: 8px 8px;">
          <h3>90 Days Paid vs Expired</h3>
          <p style="font-style: italic;">Expired vs Paid subs from the last 90 days based on subnumber.</p>
          <button mat-raised-button (click)="paidVsExpired()">Paid Vs Expired</button>
        </div>

      </mat-card-content>

    </mat-card>

  </div>

  <div style="flex: 1; display: flex; flex-direction: column; gap: 16px;">
    <section [hidden]="subscribers.length === 0" class="search-results mat-elevation-z8">
      <div #tableContainer class="table-container">
        <table mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
        <!-- Position Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let element">{{element.subid}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"><a [href]="'/subscribers/' + element.subid">{{element.subfirstname}} {{element.sublastname}}</a></td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="altname">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Alt Name </th>
          <td mat-cell *matCellDef="let element"><a [href]="'/subscribers/' + element.subid">{{ element.subbandname ? element.subbandname : element.subartistname ? element.subartistname :  element.subbusinessname ? element.subbusinessname : element.petname ? element.petname : element.nameoncard }}</a></td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      
        <ng-container matColumnDef="email">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element">{{element.subemail}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Phone </th>
          <td mat-cell *matCellDef="let element">{{element.submobile}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      
        <ng-container matColumnDef="subtype">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Sub Type </th>
          <td mat-cell *matCellDef="let element">{{element.subscription && element.subscription.subtypecode}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="subnumber">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Sub No </th>
          <td mat-cell *matCellDef="let element">{{element.subnumber}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      
        <ng-container matColumnDef="expiry">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Expiry </th>
          <td mat-cell *matCellDef="let element">{{element.expirydate}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="suburb">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Suburb </th>
          <td mat-cell *matCellDef="let element">{{element.delivery_address && element.delivery_address.locality }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row (click)="clickedRows(row)" [class.selected-row]="searchResultsSelection === row" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div style="display: flex; align-items: center; background-color: #424242;">
      <button (click)="exportCSV()" matTooltip="Export period as spreadsheet" matTooltipPosition="above" mat-icon-button style="margin-left: 8px; color: #a8a8a8;" aria-label="export period as spreadsheet">
        <mat-icon>table_chart</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Columns" matTooltipPosition="above" style="margin-left: 8px; color: #a8a8a8;">
        <mat-icon>view_column</mat-icon>
      </button>
      <mat-menu #menu="matMenu" > 
        <div style="max-height: 300px; min-width: 150px; overflow-y: scroll;">
          <div *ngFor="let col of allColumns; index as c">
            <mat-checkbox [checked]="col.visible" (change)="changeColumns(c, $event)">
              {{col.column}}
            </mat-checkbox>
          </div>
        </div>
      </mat-menu>
      <mat-paginator 
        #subsPaginator
        style="flex: 1"
        [length]="resultsLength" 
        [pageSize]="40"
        showFirstLastButtons
        aria-label="Select page of orders">
      </mat-paginator>
    </div>
    </section>

    <mat-card *ngIf="emails !== ''">
      <mat-card-header>
        <mat-card-title style="display: flex">
          <div style="flex: 1">Emails</div>
          <button (click)="copyEmails()" mat-icon-button matTooltip="Copy Emails">
            <mat-icon>
              content_cut
            </mat-icon>
          </button>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field class="full-width">
          <mat-label>Email List</mat-label>
          <textarea #emailArea rows="10" disabled="true" matInput [(ngModel)]="emails"></textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Total active subscribers: {{tallyTotal}}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="tally" style="padding-top: 16px;">
          <table>
            <tr *ngFor="let subtype of tally">
              <td>
                <strong style="margin-right:60px;">{{subtype.subtype}}:</strong>
              </td>
              <td>{{subtype.subtypecount}}</td>
            </tr>
          </table>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card style="background-color: white; color: black;">
      <mat-card-header>
        <mat-card-title>
          Graphs
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <canvas *ngIf="incremental"
          style="margin: 16px 8px;"
          baseChart
          type="line"
          [data]="incremental"
        >
        </canvas>

        <div *ngIf="data">
          
          <mat-slide-toggle [(ngModel)]="venn" hideIcon="true">Venn</mat-slide-toggle>

          <canvas
            style="margin: 16px 8px;"
            baseChart
            [type]="venn ? 'venn' : 'euler'"
            [data]="data"
            [options]="vennOptions"
          >
          </canvas>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</section>