<mat-toolbar [style.padding-right]="routerState === 'releases/archive' || routerState === 'releases/browse' ? '72px' : ''"  class="releases-toolbar" style="position: sticky; top: 0; z-index: 100;">
  <h3 *ngIf="!qs">Music Library</h3>
  <mat-form-field *ngIf="qs" class="full-width quick-search"  style="flex: 1">
    <input matInput #quickSearchEl placeholder="Quick Search" (keydown.enter)="quickSearch($event)" [value]="searchSub">
    <button *ngIf="searchSub" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch(); qs = false;">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div *ngIf="!qs" style="flex: 1"></div>
  
  <div class="toolbar-buttons-wide">
    <ng-container *ngIf="!qs">
      <button mat-button (click)="toggleQuickSearch()">Search</button>
    </ng-container>
    <a *ngIf="qs" mat-button routerLink="/releases/search" routerLinkActive="active" (click)="toggleQuickSearch()">Advanced Search</a>
    <a mat-button routerLink="/releases/browse" routerLinkActive="active">Digital Pigeonhole</a>
    <a mat-button routerLink="/releases/archive" routerLinkActive="active">Archive</a>
    <!-- <a mat-button routerLink="/releases/playlists" routerLinkActive="active">Playlists</a> -->
    <a *ngIf="requireAccess('music')" mat-button routerLink="/releases/new" routerLinkActive="active">Add New Release</a>
  </div>

  <div class="toolbar-buttons-narrow">
    <button mat-icon-button (click)="toggleQuickSearch()" aria-label="toggle quick search">
      <mat-icon>search</mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="toolbarMenu" aria-label="menu" >
      <mat-icon>arrow_drop_down_circle_outline</mat-icon>
    </button>
    <mat-menu #toolbarMenu="matMenu">
      <a mat-menu-item routerLink="/releases/search" routerLinkActive="active">Advanced Search</a>
      <a mat-menu-item routerLink="/releases/browse" routerLinkActive="active">Digital Pigeonhole</a>
      <a mat-menu-item routerLink="/releases/archive" routerLinkActive="active">Archive</a>
      <a *ngIf="requireAccess('music')" mat-menu-item routerLink="/releases/new" routerLinkActive="active">Add New Release</a>
    </mat-menu>
  </div>
  
  <button [style.background-color]="showFavouiteList ? 'rgb(119, 43, 99)' : 'rgba(119, 43, 99, 0.5)'" mat-mini-fab (click)="toggleFavouriteList()" aria-label="toggle favourite list">
    <mat-icon>{{showFavouiteList ? 'star' : 'star_border'}}</mat-icon>
  </button>
</mat-toolbar>

<ng-container *ngIf="showFavouiteList">
  <mat-toolbar style="position: sticky; top: 0; z-index: 100;">

    <h3 style="flex: 1;">Favourites</h3>
    <button mat-icon-button (click)="downloadPlaylist()" matTooltip="Download Favourites Playlist" aria-label="download favourites as playlist">
      <mat-icon>save_alt</mat-icon>
    </button>
    <button *ngIf="requireAccess('announcer')" mat-icon-button (click)="savePlaylist()" matTooltip="Save Playlist" aria-label="save playlist">
      <mat-icon>playlist_add</mat-icon>
    </button>
    <button mat-icon-button (click)="clearFavourites()" matTooltip="Clear Favourites" aria-label="clear favourites">
      <mat-icon>delete_forever</mat-icon>
    </button>
    <button mat-icon-button (click)="playPlaylist()" matTooltip="Play Favourites Playlist" aria-label="play favourites playlist">
      <mat-icon>play_arrow</mat-icon>
    </button>
    <button mat-icon-button (click)="toggleFavouriteList()" matTooltip="Close Favourites" aria-label="close favourites">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <div *ngIf="favourites" cdkDropList [cdkDropListData]="favourites.playlist" (cdkDropListDropped)="drop($event)" class="favourites-playlist">
    <div *ngFor="let track of favourites.playlist" cdkDrag (cdkDragMoved)="dragMoved($event)" (cdkDragEntered)="onCdkDragEntered($event)" (cdkDragDropped)="onCdkDragDropped($event)" [cdkDragData]="{ title_id: track.title_id }" class="track-details" style="display: flex; cursor: move;">
      <ng-template #itemTpl>
      <div class="play-number">
        <button class="play-icon" mat-icon-button aria-label="Play track" (click)="play(track, $event)" >
          <mat-icon>play_arrow</mat-icon>
        </button>
        <span style="margin: 0 4px;" class="track-number">{{track.discnum ? track.discnum + '–' : '' }}{{track.tracknum}}</span>
      </div>

      <div class="artist">
        <span>{{ ( track.artist && track.artist.name ) ? track.artist.name : track.artist}}</span>
      </div>

      <div class="title">
        {{track.title}}
      </div>

      <div class="release">
        <a [routerLink]="'/releases/'+ track.library_no">{{track.albumName}}</a>
      </div>

      <div class="year">
        {{track.year}}
      </div>

      <div class="duration">
        {{track.length | hms}}
      </div>
    </ng-template>  
    <div *cdkDragPlaceholder class="track-details" style="display: flex; width: 100%">
      <div *ngIf="draggingOutsideSourceList; else itemTpl" class="customPlaceHolder">
        Add to playlist
      </div>
    </div>
    <ng-container *ngTemplateOutlet="itemTpl"></ng-container>

      <div *cdkDragPreview class="playlist-track"><span style="padding: 2px 16px;">{{ ( track.artist && track.artist.name ) ? track.artist.name : track.artist}} - {{track.title}}</span></div>
      <!-- <div *cdkDragPlaceholder class="playlist-track"><span style="padding: 2px 16px;">{{ ( track.artist && track.artist.name ) ? track.artist.name : track.artist}} - {{track.title}}</span></div> -->
    </div>
  </div>
</ng-container>

<div class="mat-typography search-results" *ngIf="searchSub !== ''">

  <div *ngIf="artistResult">
    <app-artist-info [clear]="toggleQuickSearch" [artists]="artistResult"></app-artist-info>
  </div>

  <div *ngIf="searchResult.length">
    <app-cover-player [clear]="toggleQuickSearch" [releases]="searchResult" [release]="click" [play]="play" [queue]="queueRelease" [favourite]="favourite"></app-cover-player>
  </div>

  <div *ngIf="tracks.length" style="padding: 16px;">
    <div style="display: flex">
      <h3>Tracks</h3>
      <div style="flex: 1"></div>
      <div><b>{{tracks.length}}</b> found</div>
    </div>
    <app-album-track-list [tracks]="tracks" [play]="play"></app-album-track-list>
  </div>
  <div *ngIf="tempTracks.length" style="padding: 16px;">
    <div style="display: flex">
      <h3>Temporary Tracks</h3>
      <div style="flex: 1"></div>
      <div><b>{{tempTracks.length}}</b> found</div>
    </div>
    <app-album-track-list [tracks]="tempTracks" [play]="playTemp"></app-album-track-list>
  </div>

</div>

<router-outlet></router-outlet>
<!-- <ui-view></ui-view> -->
