import { Component, Inject, ViewChild } from '@angular/core';
import { HttpClient, HttpEventType, HttpParams, HttpBackend } from '@angular/common/http';
import { map, startWith, filter, debounceTime, switchMap, tap, distinctUntilChanged } from 'rxjs/operators';
import { FormControl, UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlaylistService } from '../playlist.service';
import { SimpleMetadataEditorComponent } from '../simple-metadata-editor/simple-metadata-editor.component';
import { Observable, Observer, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { I } from '@angular/cdk/keycodes';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SaveDialogComponent } from '../save-dialog/save-dialog.component';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
  
function toColor(num) {
  num >>>= 0;
  var b = num & 0xFF,
      g = (num & 0xFF00) >>> 8,
      r = (num & 0xFF0000) >>> 16,
      a = ( (num & 0xFF000000) >>> 24 ) / 255 ;
  return [r, g, b, a];
}

function fromColor(rgb) {
  return rgb[2] << 16 | rgb[1] << 8 | rgb[0];
}

@Component({
  selector: 'app-zed-digital',
  templateUrl: './zed-digital.component.html',
  styleUrls: ['./zed-digital.component.scss']
})

export class ZedDigitalComponent {
  @ViewChild('showCalendar', { static: false }) showCalendar!: MatCalendar<Date>;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    public dialog: MatDialog,
    private PlaylistService: PlaylistService,
    private AuthenticationService: AuthenticationService,
    private router: Router
		// @Inject('$rootScope') private $rootScope: any,
  ) {

    this.service = this.router.routerState.snapshot.url === '/emergency-broadcast' ? 'eb' : 'zd';


    this.pureHttp = new HttpClient(handler);
    this.fetchGrid();
    this.getZDPlaylists();
    // this.getZDRotation();
    this.getZDRotations();
    this.getZDSchedule();

    // console.log(this.router.routerState.snapshot.url)


    this.timeSelector.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    )
    .subscribe (query => {
      console.log(query);

      this.onDateSelected(this.selectedDate);

    });


    this.programSelector.valueChanges.subscribe({
      next: (result: any) => {
        console.log(result);

        let [hours, minutes, seconds] = result.start.split(':');
    
        this.selectedDate = moment().weekday( result.day > new Date().getDay() ? result.day - 7 : result.day ).hour(hours).minute(minutes).second(seconds);

        this.selectedSlug = result.slug;
        this.selectedProgram = result;
        if(this.showCalendar) {
          this.showCalendar.updateTodaysDate()
        }
        this.getFolder();

        this.onDateSelected(this.selectedDate);
      }
    })

    // this.bla = this.test.asObservable();
    // console.log(this.bla);
  }
  pureHttp: HttpClient;

  playlistGroup: any[] = [];
  rotations: any[] = [];
  playlistGroupLength: number = 0;
  generatedPlaylistLength: number = 0;
  generatedPlaylist: any[] = [];
  rotationControl = new UntypedFormControl();

  scheduleEditor = false;
  rotationEditor = false;

  zdPlaylists: any[] = [];
  zdPrograms: any[] = [];
  programSelector: any = new UntypedFormControl(null);
  timeSelector: any = new UntypedFormControl( new Date(1970, 0, 1, 6, 0, 0).toLocaleTimeString("en-AU", { hour12: false }) );
  selectedSlug: string | null = null;
  selectedProgram: any = {};
  selectedDate: Moment | null = null;
  uploadFolder = {folder: '', files: []};
  programFolder = {folder: '', files: []};
  foldermessage = '';
  files: any[] = [];

  schedule: any[] = Array.from({length: 7}, (e, i)=> Array.from({length: 24}, (e, i)=> ({ index: i, data: {} }) ) ); 
 

  days: number[] = Array.from({length: 7}, (e, i)=> i);
  minutes: string[] = Array.from({length: 24}, (e, i)=> moment("12:00AM", "HH:mmA").day(0).add(i * 60, 'minutes').format("hh:mma"))

  cwd: string = '';
  bruceFile = '';

  tracks: any[] = [];
  discardList: any[] = [];

  test = new Subject();

  bla: any;

  gen = { times: 0, runningTotal: 0}

  section = 'show';

  service = 'zd';
  playlistType: any;

  changeTime(event) {
    console.log(event);
    console.log(this.timeSelector.value)
  }
   
  onSectionChange = (value) => {
    this.section = value
  }

  requireAccess = (acl) => this.AuthenticationService.requireAccess(acl);

  saveSchedule() {
    console.log(this.schedule)
    let service = this.service === 'eb' ? 2 : 1;
    this.http.post<any>(environment.musicDBapiSrc + '/automation/' + service ,{ rotations: this.schedule}).subscribe( data => {
      console.log(data)
    });
  }

  queuePlaylist(playlist) {
    this.PlaylistService.prepForBroadcast({ playlist: playlist });
  }

  addToPlaylist(playlist) {
    console.log(playlist)
    this.playlistGroup.push(playlist);
    this.playlistGroupLength = 0;
    this.playlistGroup.forEach( item => {
      item.accumLength = this.playlistGroupLength;
      this.playlistGroupLength += Number(item.average_length);
    })
  }

  generateWeekPlaylist() {
    let service = this.service === 'eb' ? 2 : 1;
    this.http.get<any>(environment.musicDBapiSrc + '/generate-schedule-m3u/' + service ).subscribe( data => {

    });

  }

  regenerateProgramPlaylist() {
    let service = this.service === 'eb' ? 2 : 1;
    let date = this.selectedDate?.format('yy-MM-DD');
    let time = this.timeSelector.value;
    let rotation = this.playlistType.id;
    
    this.http.post<any>(environment.musicDBapiSrc + '/generate-m3u-playlist', { rotation: rotation, service: service, date: date, time: time } ).subscribe( data => {

      if (data.playlist === false) {
        alert('No playlist');
        this.tracks = [];
        return;
      }
      let m3u: any[] =  data.playlist.split(/\r\n|\n\r|\n|\r/);
      // const m3uCopy = [...m3u];
      // for(let n = m3uCopy.length - 1; n >= 0; n--) {
      //   let i = m3uCopy[n];
      //   if(i.search('Sponsorship Spots') > -1 || i.search('Station & Program Stings') > -1  ) { 
      //     m3u.splice(n-1, 2);
      //     console.log(i, n)
      //   } 
      // }
      
      let files: any[] = []; 
      let extinf = m3u.filter((i,n) => { if (i.match(/^#EXTINF/)) { files.push( m3u[n+1] ); return true; } else { return false; } });
      let tracks = extinf.map((i, n) => {
        let inf = i.match(/#EXTINF:([\d.]*), *(.*)/);
        let splParts = inf[2].substr(1).split('|')
        // let splParts = inf[2].substr(1).slice(0, -1).split('|')
        return { file: files[n], length: +inf[1], artist: splParts[0], title: splParts[1], category: splParts[2], eventType: +splParts[3], eventMinute: splParts[4], eventSecond: splParts[5], type: +splParts[6], introTime: +splParts[7], internal: +splParts[8], segueDB: +splParts[9], cuePt: +splParts[10], crossfade: +splParts[11], sequePt: +splParts[12], other: splParts[13], outroPt: +splParts[14], color: +splParts[15], colorRGBA: toColor(+splParts[15]), client: splParts[16], fade: +splParts[17], splParts: inf[2] } 
      })
      console.log(tracks)
      this.tracks = tracks;
      this.playlistType = data.rotation;
      
    });
  }


  generatePlaylist() {

    let rotation = this.rotationControl.value;
    console.log(this.schedule)
    this.http.get<any>(environment.musicDBapiSrc + '/generate-rotation-playlist/' + rotation ).subscribe( data => {
      this.generatedPlaylist = data;


      this.generatedPlaylistLength = 0;
      this.generatedPlaylist.forEach( item => {
        item.accumLength = this.generatedPlaylistLength;
        this.generatedPlaylistLength += Number(item.splitlength);
      })
      this.gen.times += 1;
      this.gen.runningTotal += this.generatedPlaylistLength;
    });
  }

  updateLeftScroll(scrollOne: HTMLElement, scrollTwo: HTMLElement){
    scrollTwo.scrollTop = scrollOne.scrollTop;
    // scrollTwo.scrollBy = scrollOne.scrollBy;
  } 
  updateRightScroll(scrollTwo: HTMLElement, scrollOne: HTMLElement) {
    scrollOne.scrollTop = scrollTwo.scrollTop;
    //  scrollOne.scrollBy = scrollTwo.scrollBy;
  }
 
  getDay = (day: number) => {
    return moment().day(day).format('dddd');
  }

  onRotationDrop = ($event: CdkDragDrop<any>, array, day, minute, $index) =>{
    console.log($event)
    
    let $data = $event.item.data;

    if($event.container.data) {

      this.schedule[$event.container.data.day][$event.container.data.minute]['data'] = $data;

    }

    else { 

    }
    //console.log($data)
    //console.log(array)
    //console.log(day)
    //console.log(minute)
    //console.log($index)
    if (day === 'unused' ) {
      this.discardList.push($data);
    }
    // this.programs[day].push({ data: $data, minute: minute, index: $index } );

  };

  debug = ($event, day, minute) => {
    console.log(day, minute, $event)
  }
  
  remove = (event) => {
    if (event.previousContainer === event.container) {
    }
    else {
      this.playlistGroup.splice(event.previousIndex, 1);
      this.playlistGroupLength = 0;
      this.playlistGroup.forEach( item => {
        item.accumLength = this.playlistGroupLength;
        this.playlistGroupLength += Number(item.average_length);
      })
    }

  }
  drop = (event) => {

    if (event.previousContainer === event.container) {
      moveItemInArray(this.playlistGroup, event.previousIndex, event.currentIndex);
    }
    else {
      
      // this.playlistGroup.push({ ...event.item.data })
      this.playlistGroup.splice(event.currentIndex, 0, { ...event.item.data })
    }

    this.playlistGroupLength = 0;
    this.playlistGroup.forEach( item => {
      item.accumLength = this.playlistGroupLength;
      this.playlistGroupLength += Number(item.average_length);
    })
    console.log(event)
  }

  myFilter = (d: Moment | null): boolean => {
    const day = (d || moment()).day();
    // Prevent Saturday and Sunday from being selected.
    if (this.selectedProgram.day ) {
      return day === this.selectedProgram.day % 7;
    }
    return day !== 0 && day !== 6;
  };

  onDateSelected(date) {

    
    let [hours, minutes, seconds] = this.service === 'zd' ? this.selectedProgram.start.split(':') : this.timeSelector.value.split(':');
    let timeslot = moment(date).hour(0).minute(0).second(0).millisecond(0).add(hours, 'hours').add(minutes, 'minutes').add(seconds, 'seconds');
    let showPlaylist = timeslot.format('MMMDD-HH');
    let archiveDate = timeslot.format('YYYY-MM-DD-HH-mm');

    this.getPlaylist(showPlaylist, archiveDate);
    this.bruceFile = 'ZD-' + archiveDate + '.mp3';
  }

  fetchGrid() {
    this.pureHttp.get<any>(
      'https://airnet.org.au/rest/stations/4ZZZ/guides/digital', 
    ).subscribe( data => { this.zdPrograms = data.map( i => ({ ...i, name: i.name.replace('&amp;', '&')  })) })
  }

  updateFolder = out => {
    console.log(out)
    this.uploadFolder = out;
  }


  getFolder = (folder = null) => {
    let path;
    if(!folder) {
      path = '/Digital Radio/Zed Digital PROGRAMS/' + this.selectedProgram.name + '/';
    }
    else {
      path = folder;
    }
    this.cwd = path;
    this.http.post<any>(environment.musicDBapiSrc + '/list-fela', { folder: path } ).subscribe (data => { 
      if(data) {
        this.programFolder = data 
        this.uploadFolder = data 
      }
      else {
        this.foldermessage = 'No folder found, create a folder in Digital Radio with the same name.';
      }
    });
  }

  tagEditor = ($event, file) => {
    this.dialog.open(SimpleMetadataEditorComponent, {
      data: {
        file: file,
      },
    });
  }

  getZDPlaylists = () => {
    this.http.get<any>(environment.musicDBapiSrc + '/zd-playlists').subscribe( data => {
      this.zdPlaylists = data;
    });
  }


  getZDSchedule = () => {

    let service = this.service === 'eb' ? 2 : 1;
    this.http.get<any>(environment.musicDBapiSrc + '/automation/' + service).subscribe( data => {
      data.rotations.forEach( hour => { 
        this.schedule[hour.pivot.day][hour.pivot.hour]['data'] = { id: hour.id, name: hour.name }
      });
    });
  }


  getZDRotations = () => {
    this.http.get<any>(environment.musicDBapiSrc + '/rotations').subscribe( data => {
      this.rotations = data;
    });
  }


  newRotation = () => {
    this.playlistGroup = [];
    this.playlistGroupLength = 0;
    this.rotationControl.reset();

  }


  saveRotationAs = () => {
    const dialogRef = this.dialog.open(SaveDialogComponent, {
      data: {
        playlists: [ this.playlistGroup ]
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result !== 'abort') {
        console.log(result);

        this.http.post<any>( environment.musicDBapiSrc + '/create-rotation', { name: result, playlists: this.playlistGroup.map( ( i, n ) => ( { playlist_id: i.id, rank: n + 1  } )) } ).subscribe( data => {
          this.rotations = data.rotations;

          this.rotationControl.patchValue(data.rotation.id);

          this.playlistGroup = data.rotation.playlists;

          this.playlistGroupLength = 0;
          this.playlistGroup.forEach( item => {
            item.accumLength = this.playlistGroupLength;
            this.playlistGroupLength += Number(item.average_length);
          })
        })
      }
    });
  }

  saveRotation = () => {
    let id = this.rotationControl.value;
    this.http.post<any>( environment.musicDBapiSrc + '/save-rotation/' + id, { playlists: this.playlistGroup.map( ( i, n ) => ( { playlist_id: i.id, rank: n + 1  } )) }).subscribe( data => {
      
      this.playlistGroup = data.playlists;

      this.playlistGroupLength = 0;
      this.playlistGroup.forEach( item => {
        item.accumLength = this.playlistGroupLength;
        this.playlistGroupLength += Number(item.average_length);
      })
    })
  }

  getRotation = (id) => {
    this.http.get<any>(environment.musicDBapiSrc + '/rotation/' + id).subscribe( data => {
      this.playlistGroup = data.playlists;

      this.playlistGroupLength = 0;
      this.playlistGroup.forEach( item => {
        item.accumLength = this.playlistGroupLength;
        this.playlistGroupLength += Number(item.average_length);
      })
    });
  }

  getZDRotation = () => {
    this.http.get<any>(environment.musicDBapiSrc + '/rotation/1').subscribe( data => {
      this.playlistGroup = data.playlists;

      this.playlistGroupLength = 0;
      this.playlistGroup.forEach( item => {
        item.accumLength = this.playlistGroupLength;
        this.playlistGroupLength += Number(item.average_length);
      })
    });
  }


  getPlaylist =  (playlist, date) => {
    this.http.post<any>(environment.musicDBapiSrc + '/playlist', {playlist: playlist, date: date, automation: this.service}).subscribe( data => {
      // this.showPlaylist = data
      if (data.playlist === false) {
        alert('No playlist');
        this.tracks = [];
        return;
      }
      let m3u: any[] =  data.playlist.split(/\r\n|\n\r|\n|\r/);
      // const m3uCopy = [...m3u];
      // for(let n = m3uCopy.length - 1; n >= 0; n--) {
      //   let i = m3uCopy[n];
      //   if(i.search('Sponsorship Spots') > -1 || i.search('Station & Program Stings') > -1  ) { 
      //     m3u.splice(n-1, 2);
      //     console.log(i, n)
      //   } 
      // }
      
      let files: any[] = []; 
      let extinf = m3u.filter((i,n) => { if (i.match(/^#EXTINF/)) { files.push( m3u[n+1] ); return true; } else { return false; } });
      let tracks = extinf.map((i, n) => {
        let inf = i.match(/#EXTINF:([\d.]*), *(.*)/);
        let splParts = inf[2].substr(1).split('|')
        // let splParts = inf[2].substr(1).slice(0, -1).split('|')
        return { file: files[n], length: +inf[1], artist: splParts[0], title: splParts[1], category: splParts[2], eventType: +splParts[3], eventMinute: splParts[4], eventSecond: splParts[5], type: +splParts[6], introTime: +splParts[7], internal: +splParts[8], segueDB: +splParts[9], cuePt: +splParts[10], crossfade: +splParts[11], sequePt: +splParts[12], other: splParts[13], outroPt: +splParts[14], color: +splParts[15], colorRGBA: toColor(+splParts[15]), client: splParts[16], fade: +splParts[17], splParts: inf[2] } 
      })
      console.log(tracks)
      this.tracks = tracks;
      this.playlistType = data.rotation;
    })
  }

  openFile = ($event, file) => {
    const extension = file.split('.').pop()
    const uncompressedAudioTypes = ['wav', 'aiff', 'aif']
    const compressedAudioTypes = ['mp3', 'm4a', 'ogg', 'flac']
    const playlistTypes = ['m3u']
    const uncompressedAudio = uncompressedAudioTypes.includes( extension )
    const compressedAudio = compressedAudioTypes.includes( extension )
    const playlist = playlistTypes.includes( extension )
    if(compressedAudio)
      return this.tagEditor($event, file)
    if(playlist)
      return this.getPlaylist(file, undefined)
  }

  onMediaDropped($event) {
    this.prepareMediaList($event);
  }

  prepareMediaList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadMedia();
    // this.uploadFilesSimulator(0);
  }

  mediaBrowseHandler(target ) {
    this.prepareMediaList(target.files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }
  
  getEventMessage = (event, file, chunkNumber, percentTotal) => {
    // console.log(event, file);
    // console.log(chunkNumber)
    if (event && event.type === HttpEventType.UploadProgress) {
      if(event.loaded &&
        event.total) {
        const percentDone = Math.round(100 * event.loaded / event.total);
        percentTotal[chunkNumber] = percentDone;
        let percentChunks = Math.round( percentTotal.reduce((sum, a) => sum + a, 0) / percentTotal.length );
        file.progress = percentChunks;
      }
    } 
  }

  uploadMedia() { 
    if (!this.files.length) {
      return;
    } else {
      this.files.forEach( async (file, n) => {

        const chunkSize = 10485760;
        let chunkNumber = 0;
        const chunkTotal = Math.ceil(file.size / chunkSize);
        let percentTotal = Array(chunkTotal).fill(0) 
         for( let offset = 0; offset < file.size; offset += chunkSize ){
          const filename = file.name;
          const chunk = file.slice( offset, offset + chunkSize );
          const formData = new FormData();
          formData.append('_chunkSize', String(chunkSize));
          formData.append('_currentChunkSize', String(chunk.size));
          formData.append('_chunkNumber', String(chunkNumber));
          formData.append('_totalSize', String(file.size));
          formData.append('file', chunk, filename);
          formData.append('destination', this.uploadFolder.folder);
            const resp = await this.http.post<any>(environment.musicDBapiSrc + '/upload', formData, {
              observe: 'events',
              reportProgress: true,
            }).pipe(  tap(event => this.getEventMessage(event, file, chunkNumber, percentTotal)) ).toPromise() 

              if (resp && resp.type === HttpEventType.Response) {
              if(resp.body) {
                console.log('Upload complete');
                console.log(resp.body);
                if (resp.body.mime && resp.body.mime === 'application/zip') { 
                  let index = this.files.indexOf(file);
                  this.deleteFile(index);
                  this.getFolder()
                  this.test.next('refresh');
                }
                else {
                  let index = this.files.indexOf(file);
                  this.deleteFile(index);
                  this.getFolder()
                  this.test.next('refresh');
                }
                
              }
            }
          chunkNumber++;
        }
        
      })
    }
  }
}



// Format: %nx, where n = optional padded size
// x = parameters as follows:

// %u = unmodified path\filename							%E = timed event type (0-3)
// %p = file path (no trailing \)							%H = timed event hour
// %F = filename (with extension)							%M = timed event minute
// %f = filename (no extension)								%m = timed event second
// %e = file extension (.ext)									%q = cue point (ms)
// %a = artist																%o = Crossfade overlap (ms)
// %t = title																	%Q = segue point (ms)
// %N = client																%r = color
// %S = song duration (seconds)								%C = track type
// %s = song duration (mm:ss)									%c = category name
// %d = playlist duration (hh:mm:ss)					%i = track intro time (ms)
// %z = playlist duration (ms)								%I = track outro point (ms)
// %D(format) = scheduled date								%L = track number
// %h = scheduled time (hh:mm:ss)							%n = new line (cr/1f)
// %O = Other																	%b = tab character

// %Y Internal use. Value 0. 
// %Z 0 = Studio will calculate the Cue & Segue points, 1 = Studio will use the %q and %Q values above for the Cue & Segue points

// Type 
// Description
// 0 Category (song) 
// 1 General Spot 
// 2 Jingle (special meaning in Studio) 
// 3 Break note 
// 5 Empty playlist marker. This represents a ...NONE... virtual rotation. 
// 6 Voice track marker 
// 7 Song intro 
// 8 Song outro 
// 9 Voice track 
// 10 Commercial intro 
// 11 Commercial


// Literals may be included.

// durationsec, |artist|title|categoryname|timeevtype|timeevmin|timeevsec|tracktype|introtime|internal|cueseqtog|cuept|crossfade|seguept|other|outropt|color|client|fadespped newline filepath\filepath .filext
// #EXTINF:%S,|%a|%t|%c|%E|%M|%m|%C|%i|%Y|%Z|%q|%o|%Q|%O|%I|%r|%N|%P%n%p\%f%e
// #EXTINF:%S,|%a|%t|%c|%E|%M|%m|%C|%i|%Y|%Z|%q|%o|%Q|%O|%I|%r|%N|%P%n%p\%f%e