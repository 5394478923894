<mat-toolbar>
	<div>Campaign</div>
</mat-toolbar>
<mat-toolbar style="position: sticky; top: 0; z-index: 150;">
  <mat-toolbar-row style="gap: 8px;">
    <h2 style="flex: 1">{{promo.title}}</h2>

    <button  mat-icon-button color="accent" matTooltip="Add Spot" (click)="addCampaign('spot')">
      <mat-icon matBadge="+" matBadgePosition="below after" matBadgeColor="accent">radio</mat-icon>
    </button>

    <button mat-icon-button color="accent" matTooltip="Add Live Read" (click)="addCampaign('live-read')">
      <mat-icon matBadge="+" matBadgePosition="below after" matBadgeColor="accent">chat</mat-icon>
    </button>

    <button mat-icon-button color="accent" matTooltip="Add Giveaway" (click)="addCampaign('giveaway')">
      <mat-icon matBadge="+" matBadgePosition="below after" matBadgeColor="accent" fontSet="material-symbols-outlined">trophy</mat-icon>
    </button>

    <button style="margin-left: 16px;" mat-mini-fab color="warn" matTooltip="Delete All Campaigns" (click)="deleteFullCampaign(promo.id)">
      <mat-icon>
        delete_forever
      </mat-icon>
    </button>

  </mat-toolbar-row>
  <mat-toolbar-row style="height: 24px;" ng-show="zedder.id">
    <ul class="inline" style="width: 100%;">
      <li>
        <strong>ID #:</strong> {{promo.id}}
      </li>
      <li>
        <strong>Created:</strong> {{promo.created_at | date:'medium'}}
      </li>
      <li>
        <strong>Last Updated:</strong> {{promo.updated_at | date:'medium'}}
      </li>
    </ul>
  </mat-toolbar-row>
</mat-toolbar>


<div class="info-container mat-typography" [formGroup]="promoForm">

  <div style="flex: 1">
    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Promo</mat-card-title>
      </mat-card-header>

      <mat-card-content>

        <mat-form-field class="full-width">
          <mat-label>Title</mat-label>
          <input required matInput placeholder="Title" formControlName="title">
        </mat-form-field>

        <mat-form-field class="full-width" formGroupName="contact">
          <mat-label>Organisation</mat-label>
          <input matInput placeholder="Organisation" formControlName="name" [matAutocomplete]="orgComplete">
          <mat-icon (click)="gotoContact()" matSuffix>link</mat-icon>
          <mat-autocomplete 
            autoActiveFirstOption
            #orgComplete="matAutocomplete" 
            >
            <mat-option (onSelectionChange)="fetchContact(contact)" (click)="fetchContact(contact)" *ngFor="let contact of orgOptions | async" [value]="contact.org_nm">
              {{contact.org_nm}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

      </mat-card-content>
    </mat-card>

    <mat-card class="info-card" *ngFor="let campaign of promo.campaigns" >
      <mat-card-header style="background-color: #1058de;">
        <mat-card-title>{{campaign.title}} Schedule</mat-card-title>
      </mat-card-header>

      <mat-card-content style="background-color: #174aac;">
        <div *ngIf="campaign.scheduled">
          <div>{{campaign.scheduled.length > 0 ? 'Scheduled Runs: ' + campaign.scheduled.length : 'Unscheduled'}}<span *ngIf="campaign.recurring"> / Projected Total: {{campaign.projectedTotal}}</span></div>
          <div *ngFor="let run of campaign.scheduled" style="padding: 16px 0">
            <div style="font-weight: bold;">{{run.slug}} <span (click)="run.toggleProjected = !run.toggleProjected">{{run.recurring ? '(recurring)' : ''}}</span></div>
            <div matListItemLine *ngIf="!run.dateTime">{{run.date}} {{run.hour + ':00'}}</div>
            <div matListItemLine *ngIf="run.dateTime">{{run.dateTime | date}}</div>

            <div *ngFor="let winner of run.winners">
              <a [href]="'/zedders/' + winner.id" style="color: hsl(7, 42%, 62.7%); font-weight: 400;">    
                {{winner.firstname}} {{winner.lastname}}
              </a>
            </div>

            <div *ngIf="run.toggleProjected">projected<div style="font-weight: 100;" *ngFor="let projected of run.projected">{{projected | date:'EEE MMM dd y h:mm:ss a'}}</div></div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

  </div>

  <div style="flex: 1">

    <div formArrayName="campaigns">
      <mat-card class="info-card" *ngFor="let campaign of refForm.controls; index as campaignGroup" [formGroupName]="campaignGroup">
        <mat-card-header [style.backgroundColor]="campaign.value['campaign_type'] === 3 ? 'hsl(7, 42%, 62.7%)' : campaignColours[campaign.value['campaign_cat']]">
          <mat-card-title style="display: flex; align-items: center;">
            <span style="flex: 1"><b>{{ campaignTypes[campaign.value['campaign_type'] - 1] }}</b> {{campaign.value['title']}}</span>
            <span style="font-style: italic;">{{campaign.value['commence_date'] | date}} - {{campaign.value['complete_date'] | date}}</span>
            <button mat-icon-button (click)="deleteCampaign(campaign.value['id'], campaignGroup)" matTooltip="Delete Campaign">
              <mat-icon>
                delete_forever
              </mat-icon>
            </button>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content *ngIf="!campaign.value['hide']">

          <mat-form-field required class="full-width">
            <mat-label>Title</mat-label>
            <input required matInput placeholder="Title" formControlName="title">
          </mat-form-field>

          <mat-form-field *ngIf="campaign.value.campaign_type != 2" required class="full-width">
            <mat-label>Script</mat-label>
            <textarea matInput placeholder="Script" formControlName="script" [cdkTextareaAutosize]="true"></textarea>
          </mat-form-field>

          <div *ngIf="campaign.value.campaign_type == 2">
          <label style="margin-left: 16px; font-size: 12px; color: #ffffffa0">Script</label>
            <editor [init]="init" style="margin: 4px 0 16px;" formControlName="script" ></editor>
          </div>

          <mat-form-field *ngIf="campaign.value.campaign_type != 2" class="full-width">
            <mat-label>Spot Location</mat-label>
            <input matInput placeholder="Spot Location" formControlName="media_location" [matAutocomplete]="mediaComplete">
            <mat-autocomplete 
              autoActiveFirstOption
              #mediaComplete="matAutocomplete"
              >
              <mat-option (click)="setMedia(media, campaignGroup)" *ngFor="let media of mediaOptions[campaignGroup] | async" [value]="media.file">
                {{media.file}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          
          <!-- <mat-form-field required class="full-width">
            <mat-label>Spot Location</mat-label>
            <input matInput placeholder="Spot Location" formControlName="media_location">
          </mat-form-field> -->

          <div style="display: flex; gap: 8px;" *ngIf="campaign.value.campaign_type != 2">
            <mat-form-field required class="full-width">
              <mat-label>Runs per Week</mat-label>
              <input matInput placeholder="Runs per Week" formControlName="runs_week">
            </mat-form-field>
          
            <mat-form-field required class="full-width">
              <mat-label>Runs per Campaign</mat-label>
              <input matInput placeholder="Runs per Campaign" formControlName="runs_campaign">
            </mat-form-field>
            
            <mat-checkbox formControlName="recurring">Recurring</mat-checkbox>
          </div>


          <mat-form-field *ngIf="campaign.value.campaign_type === 3" required class="full-width">
            <mat-label>Givaway Instructions</mat-label>
            <textarea matInput placeholder="Givaway Instructions" formControlName="instructions" [cdkTextareaAutosize]="true"></textarea>
          </mat-form-field>

          <mat-form-field *ngIf="campaign.value.campaign_type === 3" required class="full-width">
            <mat-label># Winners per Show</mat-label>
            <input matInput placeholder="# Winners per Show" formControlName="winnerspershow">
          </mat-form-field>

          <div style="display: flex; gap: 8px;">
            <mat-form-field class="full-width">
              <mat-label>Commencing</mat-label>
              <input required matInput [matDatepicker]="commencing" formControlName="commence_date">
              <mat-datepicker-toggle matIconSuffix [for]="commencing"></mat-datepicker-toggle>
              <mat-datepicker #commencing></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>Completion</mat-label>
              <input required matInput [matDatepicker]="completion" formControlName="complete_date">
              <mat-datepicker-toggle matIconSuffix [for]="completion"></mat-datepicker-toggle>
              <mat-datepicker #completion></mat-datepicker>
            </mat-form-field>
          </div>



          <mat-form-field *ngIf="campaign.value.campaign_type != 2" class="full-width">
            <mat-label>Campaign Category</mat-label>
            <mat-select matNativeControl formControlName="campaign_cat">
              <mat-option *ngFor="let cat of campaignCat; index as id" [value]="id">
                {{cat}}
              </mat-option>
            </mat-select>
          </mat-form-field>


        </mat-card-content>
      </mat-card>
    </div>

  </div>

</div>

<div class="floating-fab" [hidden]="!(formEdited && formValid)">
  <div  style="width: 64px; height: 64px; position: relative;">
    <mat-spinner *ngIf="submitLocked" diameter="72" style="position: absolute; top: 0; left: 0" />
    <button (click)="saveCampaign()" [disabled]="submitLocked" mat-fab color="primary" matTooltip="Save Campaign" aria-label="Save Campaign" style="position: absolute; top: 8px; left: 8px">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</div>
