
<!-- <mat-toolbar style="gap: 8px; position: sticky; top: 0; z-index: 5;"> -->
<mat-toolbar *ngIf="requireAccess('librarian')" style="gap: 8px; justify-content: space-between;">
	<mat-button-toggle-group #group="matButtonToggleGroup" [value]="section" (change)="onSectionChange(group.value)">
		<mat-button-toggle value="show" aria-label="Show Editor" aria-label="Show Editor">
			<mat-icon>today</mat-icon>
		</mat-button-toggle>
		<mat-button-toggle value="rotation" matTooltip="Rotation Editor" aria-label="Rotation Editor">
			<mat-icon>list</mat-icon>
		</mat-button-toggle>
		<mat-button-toggle value="schedule" matTooltip="Schedule Editor" aria-label="Schedule Editor">
			<mat-icon>calendar_view_week</mat-icon>
		</mat-button-toggle>
	</mat-button-toggle-group>
	
	<!-- {{section === 'rotation' ? 'Rotation Editor' : section === 'scheudule' ? 'Schedule Editor' : 'Show Editor'}} -->
	<!-- <div class="example-selected-value">Selected value: {{group.value}}</div> -->
	
	<!-- <button mat-mini-fab [color]="rotationEditor ? 'primary' : ''" (click)="rotationEditor = !rotationEditor; scheduleEditor = false" matTooltip="Rotation Editor"><mat-icon>list</mat-icon></button>
	<button mat-mini-fab [color]="scheduleEditor ? 'primary' : ''" (click)="scheduleEditor = !scheduleEditor; rotationEditor = false" matTooltip="Schedule Editor"><mat-icon>calendar_view_week</mat-icon></button> -->
	<mat-select style="flex: 1"  *ngIf="section === 'rotation'" [formControl]="rotationControl" (selectionChange)="getRotation($event.value)"> 
		<mat-option *ngFor="let rotation of rotations;" [value]="rotation.id">{{rotation.name}}</mat-option>
	</mat-select>
	<button *ngIf="section === 'rotation'" mat-icon-button (click)="newRotation()" matTooltip="New Rotation" ><mat-icon>add</mat-icon></button>
	<button *ngIf="section === 'rotation' && rotationControl.value" mat-icon-button (click)="saveRotation()" matTooltip="Save Rotation" ><mat-icon>save</mat-icon></button>
	<button *ngIf="section === 'rotation' && 
	!rotationControl.value" mat-icon-button (click)="saveRotationAs()" matTooltip="Save Rotation" ><mat-icon>save_as</mat-icon></button>
	<button *ngIf="section === 'schedule'"  mat-button (click)="saveSchedule()">Save</button>
	<button *ngIf="section === 'rotation'" [disabled]="!rotationControl.value" mat-icon-button (click)="generatePlaylist()" matTooltip="Generate Playlist" ><mat-icon>auto_awesome</mat-icon></button>
	<button *ngIf="section === 'schedule'" mat-icon-button (click)="generateWeekPlaylist()" matTooltip="Generate Weeks Playlist" ><mat-icon>auto_awesome</mat-icon></button>
	

</mat-toolbar>

<div *ngIf="section === 'rotation'" style="display: flex; flex-direction: row; gap: 8px; flex: 1; justify-content: space-between; margin: 8px; align-items: start;" cdkDropListGroup>
	<div class="rotation-list" (cdkDropListDropped)="remove($event)"  cdkDropList cdkDropListSortingDisabled style="display: flex; flex-direction: column;">
		<div class="rotation-box" *ngFor="let playlist of zdPlaylists; let index = index;" (dblclick)="addToPlaylist(playlist)" cdkDrag [cdkDragData]="playlist"  style="display: flex; gap: 8px;">
			<div style="flex: 1;"><span [style.background-color]="playlist.path ? '#782b63' : 'inherit'">{{playlist.name}}</span></div>
			<div style="font-weight: 200;">{{playlist.tracks_count}}</div>
			<div>~{{playlist.total_length / playlist.tracks_count | hms }}</div>
		</div>
	</div>

	<div class="rotation-list" style="display: flex; flex-direction: column; flex: 1; position: relative; top: 0; justify-content: space-between;  max-height: calc(100vh - 128px - 16px); overflow-y: scroll; padding-bottom: 37px;" (scroll)="updateRightScroll(scrollTwo, scrollOne)" #scrollTwo>
		<div class="rotation-box" style="cursor: pointer; position: sticky; top: 0; left: 0;"><div>{{generatedPlaylistLength | hms}}</div> <div>~{{ (this.gen.times > 0 ? this.gen.runningTotal / this.gen.times : 0 ) | hms}}</div> <div>{{generatedPlaylist.length + (generatedPlaylist.length === 1 ? ' item' : ' items' ) }}</div><mat-icon style="font-size: 16px; height: 14px;" (click)="queuePlaylist(generatedPlaylist)">play_arrow</mat-icon></div>
		<div class="rotation-box" *ngFor="let playlist of generatedPlaylist; let index = index;" cdkDrag style="display: flex; gap: 8px;">
			<div>{{playlist.accumLength | hms}}</div>
			<div style="flex: 1; overflow: hidden;"><div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" [style.background-color]="playlist.artist === '4ZZZ' ? '#782b63' : 'inherit'">{{playlist.artist}} - {{playlist.title}}</div></div>
			<div style="font-style: italic;">{{playlist.splitlength | hms }}</div>
		</div>
	</div>

	<div class="rotation-list playlist" cdkDropList style="display: flex; flex-direction: column; min-width: 30vw; max-width: 50vw; max-height: calc(100vh - 128px - 16px); overflow-y: scroll; padding-bottom: 37px;" cdkScrollable (cdkDropListDropped)="drop($event)" [cdkDropListData]="playlistGroup" (scroll)="updateLeftScroll(scrollOne, scrollTwo)" #scrollOne>
		<div class="rotation-box" style="cursor: pointer; position: sticky; top: 0; left: 0;"><div>{{playlistGroupLength | hms}}</div><div>{{playlistGroup.length + (playlistGroup.length === 1 ? ' item' : ' items' )}}</div></div>
		<div class="rotation-box" *ngFor="let playlist of playlistGroup; let index = index;" cdkDrag style="display: flex; gap: 8px;">
			<div>{{playlist.accumLength | hms}}</div>
			<div style="flex: 1;"><span [style.background-color]="playlist.path ? '#782b63' : 'inherit'">{{playlist.name}}</span></div>
			<div style="font-weight: 200;">{{playlist.tracks_count}}</div>
			<div style="font-style: italic;">~{{playlist.total_length / playlist.tracks_count | hms }}</div>
		</div>
	</div>
</div>

<div *ngIf="section === 'schedule'">

	<div style="display: flex; flex-flow: row; position: absolute;
  background: rgb(213, 213, 213);
  top: 200px;
  left: 200px;
  padding: 4px 2px; border-radius: 4px;
  border-color: rgb(233, 30, 99);
  border-style: solid;
  border-top-width: 12px;" cdkDrag>
		<div cdkDropList>
			<div style="color: rgb(76, 166, 166); font-size: 12px; padding: 0 4px; " cdkDrag [cdkDragData]="rotation" *ngFor="let rotation of rotations;" >{{rotation.name}}</div>
		</div>
	</div>

	<div style="display: flex; flex-direction: row;  height: calc(100vh - 128px);" cdkScrollable >
		<div style="flex: 1; overflow-y: scroll; top: 0;" cdkScrollable>
			<div style="flex: 1; margin-top: 24px; margin-left: 48px; align-items: start" class="week">
				<div class="time-col" style="margin-left: -48px; width: 48px;">
					<div style="margin-top: -24px; height: 24px; font-size: 10px; line-height: 24px;"></div>
					<div class="minute guide" drop-channel="program" *ngFor="let minute of minutes; index as $index">
						{{minute}}
					</div>
				</div>
				<div class="day-col" *ngFor="let day of days" >
					<div style="margin-top: -24px; height: 24px; font-size: 10px; line-height: 24px;">{{ getDay(day) }}</div>
					<div cdkDropList (cdkDropListEntered)="debug($event, day, minute)" (cdkDropListDropped)="onRotationDrop($event, discardList, day, minute, $index)" [cdkDropListData]="{ day: day,  minute: $index }" class="minute" *ngFor="let minute of minutes; index as $index">
						<div class="slot">{{ schedule[day][$index].data['name'] ? schedule[day][$index].data['name'] : minute }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>

<div *ngIf="section === 'show' && service === 'eb'" class="zed-digital-container mat-typography" cdkDropListGroup>

	<div class="show-column">

		<mat-calendar #showCalendar [(selected)]="selectedDate" (selectedChange)="onDateSelected($event)" />

		<mat-form-field>
			<mat-label>Time</mat-label>
			<input [formControl]="timeSelector" step="3600" matInput type="time">
		</mat-form-field>
	</div>

	<div style="flex: 1; display: flex;">
		<div *ngIf="tracks.length" style="flex: 1; display: flex; flex-flow: column;">
			<mat-toolbar style="gap: 8px; justify-content: space-between;"><div style="">Playlist </div><div>{{playlistType ? playlistType.name : ''}}</div><div>{{ selectedDate?.format('DD/MM/yy') }}  {{ timeSelector.value }}</div><button mat-icon-button (click)="regenerateProgramPlaylist()" matTooltip="Regenerate Playlist" ><mat-icon>auto_awesome</mat-icon></button></mat-toolbar>
			<app-m3u-playlist style="flex: 1; display: flex; position: relative; overflow: hidden; " [options]="{ zdPlaylist: selectedDate }" [tracks]="tracks"></app-m3u-playlist>
		</div>
		<div *ngIf="!tracks.length && selectedDate"  style="display: flex; justify-content: center; align-items: center; height: 100%;">
			No Playlist Created
		</div>
	</div>

</div>

<div *ngIf="section === 'show' && service === 'zd'" class="zed-digital-container mat-typography" cdkDropListGroup>

	<div class="show-column">
		<mat-form-field style="width: 100%" [hintLabel]="selectedProgram.name && selectedProgram.name + ' (' + selectedProgram.slug + ') ' + selectedProgram.start + ' (' + selectedProgram.day + ')'">
			<mat-label>Show</mat-label>
			<mat-select [formControl]="programSelector">
				<mat-option [value]="program" *ngFor="let program of zdPrograms">{{program.name}}</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-calendar #showCalendar *ngIf="selectedProgram.day" [(selected)]="selectedDate" (selectedChange)="onDateSelected($event)" [dateFilter]="myFilter" />

		<div *ngIf="selectedProgram.day && programFolder.folder" style="flex: 1; overflow: scroll;">
			<app-fela-browser 
				[fileCallback]="openFile" 
				[options]="{ zedDigital: true, drag: true }" 
				[folder]="programFolder.folder" 
				(out)="updateFolder($event)" 
				[program]="selectedProgram" 
				[refresh]="test"
				[playlistDate]="bruceFile">
			</app-fela-browser>
		</div>

		<div style="padding: 16px 0" *ngIf="foldermessage">
			{{foldermessage}}
		</div>

		<div *ngFor="let file of files; let i = index">
			<div style="display: flex" >
				<div style="flex: 1">
					<h4 class="name">
						{{ file?.name }}
					</h4>
				</div>
				<div class="size">
					{{ file?.size | bytes }}
				</div>
			</div>
			<mat-progress-bar mode="determinate" [value]="file?.progress"></mat-progress-bar>
		</div>

		<div *ngIf="selectedProgram.day && programFolder.folder" class="media-drop" [accept]="'audio/*,.zip'" appFileDnd (fileDropped)="onMediaDropped($event)">
			<input type="file" #mediaDropRef [accept]="'audio/*,.zip'" id="mediaDropRef" multiple (change)="mediaBrowseHandler($event.target)" />
			<label for="mediaDropRef">Drop tracks here</label>
		</div>
	</div>

	<div style="flex: 1; display: flex;">
		<div *ngIf="tracks.length" style="flex: 1; display: flex; flex-flow: column;">
			<mat-toolbar><div style="flex: 1">Playlist </div><div>{{playlistType ? playlistType.name : ''}}</div><div>{{ selectedDate?.format('DD/MM/yy') }}</div></mat-toolbar>
			<app-m3u-playlist style="flex: 1; display: flex; position: relative; overflow: hidden; " [options]="{ zdPlaylist: selectedDate }" [tracks]="tracks"></app-m3u-playlist>
		</div>
		<div *ngIf="!tracks.length && selectedDate"  style="display: flex; justify-content: center; align-items: center; height: 100%;">
			No Playlist Created
		</div>
	</div>

</div>