import { Component, ElementRef, Inject, Input, ViewChild, inject } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Countries, ProgramsShort, Subtypes } from '../zeddb.types';
import { Sort } from '@angular/material/sort';
import { compare } from '../helpers'
import { Location } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { Transition } from "@uirouter/angular";
import { E } from '@angular/cdk/keycodes';
import { Observable, Subject } from 'rxjs';
import { map, startWith, filter, debounceTime, switchMap, tap } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipEditedEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CountriesService } from '../countries.service';
import { SubtypesService } from '../subtypes.service';
import { PrizesService } from '../prizes.service';
import { ProgramsService } from '../programs.service';
import { VolDepartmentsService } from '../vol-departments.service';
import { SkillsNewService } from '../skills-new.service';
import { QualificationService } from '../qualification.service';
import { TrainingService } from '../training.service';
import { PositionsService } from '../positions.service';
import { VolunteerService } from '../volunteer.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-volunteer-details',
  templateUrl: './volunteer-details.component.html',
  styleUrls: ['./volunteer-details.component.scss']
})

export class VolunteerDetailsComponent {
  // @Input() volunteer: any = { volunteer: { id: 0 } };

  @ViewChild('departmentInput')
  departmentInput!: ElementRef<HTMLInputElement>;
  
  @ViewChild('skillInput')
  skillInput!: ElementRef<HTMLInputElement>;
  
  @ViewChild('qualificationInput')
  qualificationInput!: ElementRef<HTMLInputElement>;

  @ViewChild('trainingInput')
  trainingInput!: ElementRef<HTMLInputElement>;
  
  @ViewChild('positionInput')
  positionInput!: ElementRef<HTMLInputElement>;

  @ViewChild('currentDepartmentInput')
  currentDepartmentInput!: ElementRef<HTMLInputElement>;
  
  @ViewChild( 'volunteerPaginator', {static: false} ) paginator: MatPaginator = <MatPaginator>{};
  

  constructor(
    public CountriesService: CountriesService,
    public SubtypesService: SubtypesService, 
    public PrizesService: PrizesService,
    public ProgramsService: ProgramsService,
    public VolDepartmentsService: VolDepartmentsService,
    public SkillsNewService: SkillsNewService,
    public QualificationService: QualificationService,
    public TrainingService: TrainingService,
    public PositionsService: PositionsService,
    public VolunteerService: VolunteerService,
    
    // @Inject('$rootScope') private $rootScope: any,
    public location: Location,
    private http: HttpClient,
    // protected trans: Transition,
    private route: ActivatedRoute,
    private router: Router
  ){
    // CountriesService.query().$promise.then(response => { 
    //   this.countries = response; 
    // });

    // SubtypesService.query().$promise.then(response => { 
    //   this.subCategories = response; 
    // });

    // PrizesService.query().$promise.then(response => { 
    //   this.prizes = response; 
    // });

    VolDepartmentsService.query().subscribe(response => { 
      this.departments = response; 
    });

    SkillsNewService.query().subscribe(response => { 
      this.skills = response; 
    });
    
    QualificationService.query().subscribe(response => { 
      this.qualifications = response; 
    });
    
    TrainingService.query().subscribe(response => { 
      this.training = response; 
    });
    
    PositionsService.query().subscribe(response => { 
      this.positions = response; 
    });
    
    

    this.volunteerForm.valueChanges.subscribe( () => {
      // console.log('CHANGED!', this.volunteerForm)
      this.volunteer = Object.assign(this.volunteer, this.volunteerForm.value);
      this.formEdited = true;
    });

    this.lockObservable.subscribe( value => {
      console.log('lock: ', value)
      this.formDisabled = value;
      if (value) {
        this.volunteerForm.disable(); 
        this.departmentControl.disable();
        this.skillControl.disable();
        this.qualificationControl.disable();
        this.trainingControl.disable();
        this.positionControl.disable();
        this.currentDepartmentControl.disable();
      }
      else {
        this.volunteerForm.enable(); 
        this.departmentControl.enable();
        this.skillControl.enable();
        this.qualificationControl.enable();
        this.trainingControl.enable();
        this.positionControl.enable();
        this.currentDepartmentControl.enable();
      }
    })

  }

  ngAfterViewInit() {
    this.paginator.page.subscribe( data =>{ 
      // this.fetchOrders() 
      this.sortData(this.lastSort);
    } )
  }

  ngOnInit() {

    this.route.data.subscribe(({ volunteer }) => {
      
      console.log('populate?')
      this.volunteer = volunteer;
      this.id = this.route.snapshot.paramMap.get('id');

      if (this.id === 'search') {
        // let searchString = this.params['#'];
        let searchString = this.route.snapshot.fragment;
        if (searchString && searchString !== '') {
          this.searchString = searchString;
          this.http.post<any>(environment.apiSrc + '/volunteers-quick-search', { searchString: searchString }).subscribe(data => {
            this.searchResults = data;
            this.sortData(this.lastSort);
          })
        }
        this.volunteerForm.reset(this.initialValues);
      }

      if (this.id === 'search' || this.id === 'add') {
        this.lockObservable.next(false);
      }
      else {
        this.searchResultsSelection = {};
        console.log('populate!')
        this.populateVolunteer();
        this.lockObservable.next(false);
      }
    })

    this.departmentOptions = this.departmentControl.valueChanges.pipe( 
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.searchDepartments(name) : this.departments.slice())
    )

    this.skillOptions = this.skillControl.valueChanges.pipe( 
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.searchSkills(name) : this.skills.slice())
    )

    this.qualificationOptions = this.qualificationControl.valueChanges.pipe( 
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.searchQualifications(name) : this.qualifications.slice())
    )

    this.trainingOptions = this.trainingControl.valueChanges.pipe( 
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.searchTraining(name) : this.training.slice())
    )

    this.positionOptions = this.positionControl.valueChanges.pipe( 
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.searchPositions(name) : this.positions.slice())
    )

    this.currentDepartmentOptions = this.currentDepartmentControl.valueChanges.pipe( 
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.searchDepartments(name) : this.departments.slice())
    )
  }

  // params = this.trans.params();
  id: any = this.route.snapshot.paramMap.get('id');

  volunteer: any = { volunteer: { id: 0 } };

  lockObservable = new Subject<boolean>();
  
  formEdited = false;
  formDisabled = false;
  submitLocked = false;


  volunteerForm = new UntypedFormGroup({
    zedder:  new UntypedFormGroup({
      firstname: new UntypedFormControl({value: null, disabled: !isNaN(this.id)}), 
      lastname: new UntypedFormControl({value: null, disabled: !isNaN(this.id)}), 
      pronouns: new UntypedFormControl({value: null, disabled: !isNaN(this.id)}),
      phone: new UntypedFormControl({value: null, disabled: !isNaN(this.id)}),
      email: new UntypedFormControl({value: null, disabled: !isNaN(this.id)}),
      subnumber: new UntypedFormControl({value: null, disabled: !isNaN(this.id)}),
      expiry: new UntypedFormControl({value: null, disabled: !isNaN(this.id)}),
    }),
    contacted: new UntypedFormControl(null),
    responded: new UntypedFormControl(null),
    general_info: new UntypedFormControl(null),
    interests: new UntypedFormControl(null),
    departments: new UntypedFormControl([]),
    skills: new UntypedFormControl([]),
    qualified: new UntypedFormControl(null),
    qualifications: new UntypedFormControl([]),
    important_info: new UntypedFormControl(null),
    notes: new UntypedFormControl(null),
    completed_orientation: new UntypedFormControl(null),
    completed_orientation_date: new UntypedFormControl(null),
    active: new UntypedFormControl(null),
    training: new UntypedFormControl([]),
    positions: new UntypedFormControl([]),
    current_departments: new UntypedFormControl([]),
    roles: new UntypedFormControl(null),
    roles_history: new UntypedFormControl(null),
    announcer_history: new UntypedFormControl(null),
    project_history: new UntypedFormControl(null),
  })

  initialValues = this.volunteerForm.value;

  searchForm = new UntypedFormGroup({
    createdDateStart: new UntypedFormControl(null),
    createdDateEnd: new UntypedFormControl(null),
    inductionDateStart: new UntypedFormControl(null),
    inductionDateEnd: new UntypedFormControl(null),
  })

  allColumns: any[] = [
    { 'column': 'id', 'visible': false},
    { 'column': 'name', 'visible': true},
    { 'column': 'email', 'visible': true},
    { 'column': 'phone', 'visible': true}, 
    { 'column': 'subnumber', 'visible': true},
    { 'column': 'roles', 'visible': true},
    { 'column': 'departments', 'visible': true},  
    { 'column': 'active', 'visible': true},
  ];
  
  changeColumns = (column, $event) => {
    console.log(column, $event);
    this.allColumns[column].visible = $event.checked;
    this.updateColumns();
  }

  updateColumns = () => {
    this.displayedColumns = this.allColumns.filter(i => i.visible).map(i => i.column)
    this.displayedFilters = this.allColumns.filter(i => i.visible).map(i => i.column + '-filter')
  }
  
  page = 1;

  searchString = '';
  searchResults: any = [];
  searchResultsSelection: any = {}
  lastSort: Sort = { active: 'id', direction: '' };
  sortedData: any[] = [];
  displayedColumns: string[] = ['name', 'email', 'phone', 'subnumber',  'roles', 'departments', 'active'];
  displayedFilters: string[] = ['name-filter', 'email-filter', 'phone-filter', 'subnumber-filter', 'roles-filter', 'departments-filter', 'active-filter'];


  resultsLength = 40000;
  
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  announcer = inject(LiveAnnouncer);

  departments: any[] = [];
  departmentOptions!: Observable<any>;
  departmentControl = new UntypedFormControl('');

  skills: any[] = [];
  skillOptions!: Observable<any>;
  skillControl = new UntypedFormControl('');

  qualifications: any[] = [];
  qualificationOptions!: Observable<any>;
  qualificationControl = new UntypedFormControl('');

  training: any[] = [];
  trainingOptions!: Observable<any>;
  trainingControl = new UntypedFormControl('');


  positions: any[] = [];
  positionOptions!: Observable<any>;
  positionControl = new UntypedFormControl('');

  currentDepartmentControl = new UntypedFormControl('');
  currentDepartmentOptions!: Observable<any>;

  quickSearch = (event) => {
    let searchString = event.target.value;
    this.searchString = searchString;
    this.location.replaceState('/volunteers/search#' + searchString);
    this.http.post<any>(environment.apiSrc + '/volunteers-quick-search', { searchString: searchString }).subscribe(data => {
      this.searchResults = data;
      this.sortData(this.lastSort);
    })
  }

  exportCSV = () => {
		console.log(this.exportCols);

		const flatList = this.searchResults.map( order => 
      this.exportCols.map(i => { 
        let keys = i.field.replace(/]/g,"").replace(/\[/g,".").split(".");
        let value = keys.reduce((obj,i) => obj && (Array.isArray(obj) ? obj.map(a => a[i]).join(' / ') : obj[i]), order)
        return {key: i.displayName ? i.displayName : i.field,  value: value === null ? '' : value };
      }) 
    );
    

		console.log(flatList);
		            
    let headerNames = flatList[0].map( i => i .key );
		const csv = [
			headerNames.join(','), // header row first
			...flatList.map( order => order.map( i => i.value && JSON.stringify(i.value).replace(/\\"/g, '""')).join(',') )
		].join('\r\n')

		console.log(csv)


		let title = 'Volunteers-export'; 
		const blob = new File([csv], title + '.csv', { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		window.open(url);
	};

  dateRangeChange(dateRangeStart, dateRangeEnd) {
    console.log(dateRangeStart, dateRangeEnd);
    let {createdDateStart, createdDateEnd} = this.searchForm.value;
    console.log(createdDateStart,createdDateEnd);
    if(createdDateStart && createdDateEnd) {
      // this.fetchOrders();
    }
  }

  inductionRangeChange(dateRangeStart, dateRangeEnd) {
    console.log(dateRangeStart, dateRangeEnd);
    let {createdDateStart, createdDateEnd} = this.searchForm.value;
    console.log(createdDateStart,createdDateEnd);
    if(createdDateStart && createdDateEnd) {
      // this.fetchOrders();
    }
  }


  searchVolunteers = () => {
    // remove null / undefined / empty arrays
    const reducer = (obj) => Object.entries(obj).reduce((a,[k,v]) => (v === false || v == null || v === '' || ( v instanceof Array && v.length === 0 ) ? a : (a[k]= v instanceof Object && !(v instanceof Array) ? reducer(v) : v ,  a)), {})
        
    //convert moment to sql date format
    let searchFrom; Object.entries( this.searchForm.value ).forEach( ([k,v]) => searchFrom = { ...searchFrom, [k]: v instanceof Object && v['_isAMomentObject'] ? (v as any).format('YYYY-MM-DD') : v } );

    let volunteerForm = { ...this.volunteer, departments: this.volunteer.departments.map(a => a.id), positions: this.volunteer.positions.map(a => a.id),  current_departments: this.volunteer.current_departments.map(a => a.id), training: this.volunteer.training.map(a => a.id), qualifications: this.volunteer.qualifications.map(a => a.id), skills: this.volunteer.skills.map(a => a.id) }
    let searchTerms = reducer( { ...volunteerForm, ...searchFrom })

    //flatten nested object 
    const crushObj = (obj) => Object.keys(obj).reduce((acc, cur) => typeof obj[cur] === 'object' && !( obj[cur] instanceof Array) ? { ...acc, ...crushObj(obj[cur]) } : { ...acc, [cur]: obj[cur] } , {})

    // searchTerms = crushObj(searchTerms);

    // Object.entries( searchTerms ).forEach( ([k,v]) => { if (v instanceof Array) { searchTerms[k+'[]'] = v; delete searchTerms[k] } })


    this.http.post<any>(environment.apiSrc + '/search-volunteers', searchTerms ).subscribe( data => { 
      this.searchResults = data;

      this.resultsLength = data.length;

      this.sortData(this.lastSort);
    })
  }

  saveVolunteer = () => {
    console.log(this.volunteer);
    this.submitLocked = true;
    this.VolunteerService.update(this.volunteer).subscribe(() => {
      this.submitLocked = false;
    })
  }

  populateVolunteer = () => {
    this.volunteerForm.patchValue({
      zedder: {
        firstname: this.volunteer.zedder.firstname,
        lastname: this.volunteer.zedder.lastname,
        pronouns: this.volunteer.zedder.pronouns,
        phone: this.volunteer.zedder.phone,
        email: this.volunteer.zedder.email,
        subnumber: this.volunteer.zedder.subnumber,
        expiry: this.volunteer.zedder.expiry,
      },
      departments: this.volunteer.departments,
      contacted: this.volunteer.contacted,
      responded: this.volunteer.responded,
      skills: this.volunteer.skills,
      qualified: this.volunteer.qualified,
      qualifications: this.volunteer.qualifications,
      important_info: this.volunteer.important_info,
      general_info: this.volunteer.general_info,
      interests: this.volunteer.interests,
      notes: this.volunteer.notes,
      completed_orientation: this.volunteer.completed_orientation,
      completed_orientation_date: this.volunteer.completed_orientation_date,
      active: this.volunteer.active,
      training: this.volunteer.training,
      positions: this.volunteer.positions,
      current_departments: this.volunteer.current_departments,
      roles: this.volunteer.roles,
      roles_history: this.volunteer.roles_history,
      announcer_history: this.volunteer.announcer_history,
      project_history: this.volunteer.project_history,
    }, { emitEvent: false })

  }
  
  fetchVolunteer = (id: number) => {
    this.VolunteerService.get(id).subscribe( res => {
      this.volunteer = res;
      this.populateVolunteer();
      this.lockObservable.next(true);
    });
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.searchResults.slice( (this.paginator.pageIndex * 10), (this.paginator.pageIndex * 10) + 10  );

    // const data = this.searchResults.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.subid, b.subid, isAsc);
        case 'name':
          return compare(a.sublastname + a.subfirstname, b.sublastname + b.subfirstname, isAsc);
        case 'email':
          return compare(a.subemail, b.subemail, isAsc);
        case 'subnumber':
          return compare(a.subsubnumber, b.subsubnumber, isAsc);
        case 'expiry':
          return compare(a.expirydate, b.expirydate, isAsc);
        default:
          return 0;
      }
    });
  }

  activeSub = (sub) => { 
    let expiry = new Date(sub).valueOf();
    let now = new Date().valueOf();
    return expiry > now;
  };

  goto = (id) => {
    this.lockObservable.next(false);
    this.searchResultsSelection = {};
    this.router.navigateByUrl('/volunteers/' + id);
  }

  clickedRows = (row) => {
    console.log(row);
    this.searchResultsSelection = row;
    this.fetchVolunteer(row.id);
  };


	changeAvailability = (col,row) => {
    let availability = JSON.parse(this.volunteer.availability);
		availability[ 'avail-' + col + '-' +row ] = availability[ 'avail-' + col + '-' +row ] ? (availability[ 'avail-' + col + '-' +row ] + 1) % 3 : 1;
    this.volunteer.availability = JSON.stringify(availability);
    this.volunteerForm.updateValueAndValidity();

	}
	
  searchDepartments = (query) => {
    var results = query ? this.departments.filter((department) => { return department.department.toLowerCase().replace('-', ' ').indexOf(query.toLowerCase().replace('-', ' ')) >= 0 }) : [];
    return results;
  }

  removeDepartment(department: any, ): void {
    const index = this.volunteerForm.get('departments')!.value.indexOf(department);

    if (index >= 0) {
      this.volunteerForm.get('departments')!.value.splice(index, 1);

      this.announcer.announce(`Removed Department ${department}`);
    }
    this.volunteerForm.updateValueAndValidity();
  }

  editDepartment(department: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeDepartment(department);
      return;
    }

    // Edit existing fruit
    const index = this.volunteerForm.get('departments')!.value.indexOf(department);
    if (index >= 0) {
      this.volunteerForm.get('departments')!.value[index].theme_desc = value;
    }
    this.volunteerForm.updateValueAndValidity();
  }

  selectedDepartment(event: any): void {
    let department = event.option.value;
    this.volunteerForm.get('departments')!.value.push({ id: department.id, department: department.department});
    this.departmentInput.nativeElement.value = '';
    this.departmentControl.setValue('');
    this.volunteerForm.updateValueAndValidity();
  }

  searchSkills = (query) => {
    var results = query ? this.skills.filter((skill) => { return skill.skill.toLowerCase().replace('-', ' ').indexOf(query.toLowerCase().replace('-', ' ')) >= 0 }) : [];
    return results;
  }

  removeSkill(skill: any, ): void {
    const index = this.volunteerForm.get('skills')!.value.indexOf(skill);

    if (index >= 0) {
      this.volunteerForm.get('skills')!.value.splice(index, 1);

      this.announcer.announce(`Removed Skill ${skill}`);
    }
    this.volunteerForm.updateValueAndValidity();
  }

  editSkill(skill: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeSkill(skill);
      return;
    }

    // Edit existing fruit
    const index = this.volunteerForm.get('skills')!.value.indexOf(skill);
    if (index >= 0) {
      this.volunteerForm.get('skills')!.value[index].theme_desc = value;
    }
    this.volunteerForm.updateValueAndValidity();
  }

  selectedSkill(event: any): void {
    let skill = event.option.value;
    this.volunteerForm.get('skills')!.value.push({ id: skill.id, skill: skill.skill});
    this.skillInput.nativeElement.value = '';
    this.skillControl.setValue('');
    this.volunteerForm.updateValueAndValidity();
  }



  searchQualifications = (query) => {
    var results = query ? this.qualifications.filter((qualification) => { return qualification.qualification.toLowerCase().replace('-', ' ').indexOf(query.toLowerCase().replace('-', ' ')) >= 0 }) : [];
    return results;
  }

  removeQualification(qualification: any, ): void {
    const index = this.volunteerForm.get('qualifications')!.value.indexOf(qualification);

    if (index >= 0) {
      this.volunteerForm.get('qualifications')!.value.splice(index, 1);

      this.announcer.announce(`Removed Qualification ${qualification}`);
    }
    this.volunteerForm.updateValueAndValidity();
  }

  editQualification(qualification: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeQualification(qualification);
      return;
    }

    // Edit existing fruit
    const index = this.volunteerForm.get('qualifications')!.value.indexOf(qualification);
    if (index >= 0) {
      this.volunteerForm.get('qualifications')!.value[index].theme_desc = value;
    }
    this.volunteerForm.updateValueAndValidity();
  }

  selectedQualification(event: any): void {
    let qualification = event.option.value;
    this.volunteerForm.get('qualifications')!.value.push({ id: qualification.id, qualification: qualification.qualification});
    this.qualificationInput.nativeElement.value = '';
    this.qualificationControl.setValue('');
    this.volunteerForm.updateValueAndValidity();
  }



  searchTraining = (query) => {
    var results = query ? this.training.filter((training) => { return training.training.toLowerCase().replace('-', ' ').indexOf(query.toLowerCase().replace('-', ' ')) >= 0 }) : [];
    return results;
  }

  removeTraining(training: any, ): void {
    const index = this.volunteerForm.get('training')!.value.indexOf(training);

    if (index >= 0) {
      this.volunteerForm.get('training')!.value.splice(index, 1);

      this.announcer.announce(`Removed Training ${training}`);
    }
    this.volunteerForm.updateValueAndValidity();
  }

  editTraining(training: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeTraining(training);
      return;
    }

    // Edit existing fruit
    const index = this.volunteerForm.get('training')!.value.indexOf(training);
    if (index >= 0) {
      this.volunteerForm.get('training')!.value[index].theme_desc = value;
    }
    this.volunteerForm.updateValueAndValidity();
  }

  selectedTraining(event: any): void {
    let training = event.option.value;
    this.volunteerForm.get('training')!.value.push({ id: training.id, training: training.training});
    this.trainingInput.nativeElement.value = '';
    this.trainingControl.setValue('');
    this.volunteerForm.updateValueAndValidity();
  }




  searchPositions = (query) => {
    var results = query ? this.positions.filter((position) => { return position.position.toLowerCase().replace('-', ' ').indexOf(query.toLowerCase().replace('-', ' ')) >= 0 }) : [];
    return results;
  }

  removePosition(position: any, ): void {
    const index = this.volunteerForm.get('positions')!.value.indexOf(position);

    if (index >= 0) {
      this.volunteerForm.get('positions')!.value.splice(index, 1);

      this.announcer.announce(`Removed Position ${position}`);
    }
    this.volunteerForm.updateValueAndValidity();
  }

  editPosition(position: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removePosition(position);
      return;
    }

    // Edit existing fruit
    const index = this.volunteerForm.get('positions')!.value.indexOf(position);
    if (index >= 0) {
      this.volunteerForm.get('positions')!.value[index].theme_desc = value;
    }
    this.volunteerForm.updateValueAndValidity();
  }

  selectedPosition(event: any): void {
    let position = event.option.value;
    this.volunteerForm.get('positions')!.value.push({ id: position.id, position: position.position});
    this.positionInput.nativeElement.value = '';
    this.positionControl.setValue('');
    this.volunteerForm.updateValueAndValidity();
  }



  removeCurrentDepartment(department: any, ): void {
    const index = this.volunteerForm.get('current_departments')!.value.indexOf(department);

    if (index >= 0) {
      this.volunteerForm.get('current_departments')!.value.splice(index, 1);

      this.announcer.announce(`Removed Department ${department}`);
    }
    this.volunteerForm.updateValueAndValidity();
  }

  editCurrentDepartment(department: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeDepartment(department);
      return;
    }

    // Edit existing fruit
    const index = this.volunteerForm.get('current_departments')!.value.indexOf(department);
    if (index >= 0) {
      this.volunteerForm.get('current_departments')!.value[index].theme_desc = value;
    }
    this.volunteerForm.updateValueAndValidity();
  }

  selectedCurrentDepartment(event: any): void {
    let department = event.option.value;
    this.volunteerForm.get('current_departments')!.value.push({ id: department.id, department: department.department});
    this.currentDepartmentInput.nativeElement.value = '';
    this.currentDepartmentControl.setValue('');
    this.volunteerForm.updateValueAndValidity();
  }

  exportCols = [
    {
        field: 'id',
        displayName: 'Order ID',
    }, {
        field: 'zedder.firstname',
        displayName: 'Firstname'
    },{
        field: 'zedder.lastname',
        displayName: 'Lastname'
    }, {
        field: 'zedder.email',
        displayName: 'Email',
    }, {
        field: 'zedder.phone',
        displayName: 'Phone',
    }, {
        field: 'departments.department',
        displayName: 'Department Interests'
    }, {
        field: 'skills.skill',
        displayName: 'Skills'
    },{
        field: 'qualified',
        displayName: 'Other Skills'
    }, {
        field: 'qualifications.qualification',
        displayName: 'Qualifications'
    }, {
        field: 'important_info',
        displayName: 'Important Information'
    },{
        field: 'general_info',
        displayName: 'Transaction ID',
        visible: false
    },{
        field: 'interests',
        displayName: 'Interests',
        visible: false
    },{
        field: 'notes',
        displayName: 'Notes',
        visible: true
    },{
        field: 'completed_orientation',
        displayName: 'Completed Induction',
        visible: true
    },{
        field: 'completed_orientation_date',
        displayName: 'Induction Date',
        visible: false
    },{
        field: 'active',
        displayName: 'Active Volunteer',
        visible: true
    },{
        field: 'training.training',
        displayName: 'Internal Training'
    },{
        field: 'positions.position',
        displayName: 'Current Role'
    },{
        field: 'current_departments.department',
        displayName: 'Current Departments'
    },{
      field: 'roles',
      displayName: 'Role Notes'
    },{
      field: 'roles_history',
      displayName: 'Role History'
    },{
      field: 'announcer_history',
      displayName: 'Announcer History'
  },{
    field: 'project_history',
    displayName: 'Volunteer History'
},{
        field: 'created_at',
        displayName: 'Created'
    },{
        field: 'updated_at',
        displayName: 'Updated',
        sort: { priority: 0, direction: 'desc' },
    },{
        field: 'zedder.delivery_address.address',
        displayName: 'Address',
    },{
        field: 'zedder.delivery_address.locality',
        displayName: 'Suburb',
    },{
        field: 'zedder.delivery_address.region',
        displayName: 'State',
    },{
        field: 'zedder.delivery_address.postcode',
        displayName: 'Postcode',
    }
  ];

}
