<!-- <mat-toolbar>
  <span>Subscribers</span>
  <span style="flex: 1"></span>
  <button mat-button class="caps" aria-label="Search">Search</button>
  <button mat-button class="caps" aria-label="Add Subscription">Add Subscription</button>
</mat-toolbar> -->

<mat-toolbar class="quick-search" style="position: sticky; top: 0; z-index: 150">
  <mat-form-field class="full-width">
    <input matInput placeholder="Quick Search" (keydown.enter)="quickSearch($event)" [value]="searchString">
  </mat-form-field>
</mat-toolbar>

<section [hidden]="searchResults.length === 0" class="search-results mat-elevation-z8">
  <table mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element">{{element.subid}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"><a (click)="$event.stopPropagation()" [routerLink]="'/subscribers/' + element.subid">{{element.subfirstname}} {{element.sublastname}}</a></td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element">{{element.subemail}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> Phone </th>
      <td mat-cell *matCellDef="let element">{{element.submobile}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="subnumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Sub No </th>
      <td mat-cell *matCellDef="let element">{{element.subnumber}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="expiry">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Expiry </th>
      <td mat-cell *matCellDef="let element">{{element.expirydate}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row (click)="clickedRows(row)" [class.selected-row]="searchResultsSelection === row" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>    
</section>

<mat-toolbar style="position: sticky; top: 0; z-index: 150;">
  <mat-toolbar-row>
    <h2>{{subscriber.subfirstname}} {{subscriber.sublastname}}</h2>
  </mat-toolbar-row>
  <mat-toolbar-row style="height: 24px;" ng-show="zedder.id">
    <ul class="inline" style="width: 100%;">
      <li>
        <strong>Sub ID #:</strong> {{subscriber.subid}}
      </li>
      <li>
        <strong>Created:</strong> {{subscriber.created_at | date:'medium'}}
      </li>
      <li>
        <strong>Last Updated:</strong> {{subscriber.updated_at | date:'medium'}}
      </li>
      <li *ngIf="subscriber.zedders">
        <strong>Zedder ID #:</strong><a [routerLink]="'/zedders/' + subscriber.zedders[0].id"> {{subscriber.zedders[0].id}}</a>
      </li>
      <li>
        {{activeSub(subscriber.expirydate) ? 'Active Subscription' : 'Past Subscription' }}
      </li>
      
    </ul>
  </mat-toolbar-row>
</mat-toolbar>

<div class="info-container mat-typography" [formGroup]="subscriberForm">
  <div style="flex: 1">
    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Subscription Type</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-form-field class="full-width">
          <mat-label>Subtype</mat-label>
          <mat-select matNativeControl formControlName="subtypeid">
            <mat-option *ngFor="let subtype of subCategories" [value]="subtype.subtypeid">
              {{subtype.subtypecode}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
		</mat-card>

    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Contact Information</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="First Name" formControlName="subfirstname">
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Last Name" formControlName="sublastname">
          </mat-form-field>
        </div>

        

        <mat-form-field class="full-width" *ngIf="subscriber.nameoncard">
          <mat-label>Name On Card</mat-label>
          <input matInput placeholder="Name On Card" formControlName="nameoncard">
        </mat-form-field>

        <mat-form-field class="full-width" *ngIf="subscriber.subtypeid === 3">
          <mat-label>Business</mat-label>
          <input matInput placeholder="Business" formControlName="subbusinessname">
        </mat-form-field>

        <mat-form-field class="full-width" *ngIf="subscriber.subtypeid === 10">
          <mat-label>Community Group</mat-label>
          <input matInput placeholder="Community Group" formControlName="subcommunitygroup">
        </mat-form-field>


        <mat-form-field class="full-width" *ngIf="subscriber.subtypeid === 15">
          <mat-label>Artist/Project Name</mat-label>
          <input matInput placeholder="Artist/Project" formControlName="subartistname">
        </mat-form-field>


        <mat-form-field class="full-width" *ngIf="subscriber.subtypeid === 6">
          <mat-label>Musician</mat-label>
          <input matInput placeholder="Musician" formControlName="submusicianname">
        </mat-form-field>

        <mat-form-field class="full-width" *ngIf="subscriber.subtypeid === 14">
          <mat-label>Pet Name</mat-label>
          <input matInput placeholder="Pet Name" formControlName="petname">
        </mat-form-field>

        <div style="background-color: #3a3a3a; margin-bottom: 16px;">
          <mat-form-field class="full-width" *ngIf="subscriber.subtypeid === 4">
            <mat-label>Band Name</mat-label>
            <button (click)="addMember()" mat-icon-button matSuffix matTooltip="Add Band Member">
              <mat-icon>add_circle</mat-icon>
            </button>
            <input matInput placeholder="Band Name" formControlName="subbandname">
          </mat-form-field>


          <div formArrayName="bandmembers" style="margin: 0 16px;">
            <div *ngFor="let member of refForm.controls; index as memberGroup" [formGroupName]="memberGroup">
              <div style="display: flex; gap: 8px;">
                <mat-form-field class="full-width">
                  <mat-label>First Name</mat-label>
                  <input matInput placeholder="Member First Name" formControlName="subbandmemberfirstname">
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>Last Name</mat-label>
                  <input matInput placeholder="Member Last Name" formControlName="subbandmemberlastname">
                </mat-form-field>

                <button matTooltip="Remove Band Member" (click)="removeMember(memberGroup)" mat-icon-button><mat-icon>remove_circle</mat-icon></button>

              </div>
            </div>
          </div>  
        </div>

        <mat-form-field class="full-width">
          <mat-label>Mobile Phone</mat-label>
          <a [disabled]="!subscriber.submobile" [href]="'tel:' + subscriber.submobile" mat-icon-button matSuffix>
            <mat-icon>phone</mat-icon>
          </a>
          <input matInput placeholder="Mobile Phone" formControlName="submobile">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <a [disabled]="!subscriber.subemail" [href]="'mailto:' + subscriber.subemail" mat-icon-button matSuffix>
            <mat-icon>email</mat-icon>
          </a>
          <input matInput placeholder="Email" formControlName="subemail">
        </mat-form-field>

        <div style="font-size: 75%" *ngIf="diffZedder">This record differs from the zedder record email</div>
        <div style="font-size: 75%" *ngIf="newZedder.length === 0 && subscriber.subemail">No zedder record for this email. <a (click)="newZedderFromSub()">Create?</a></div>
        <div style="font-size: 75%" *ngIf="newZedder.length > 0">{{newZedder.length}} record/s found for this email.</div>
        <div *ngFor="let zed of newZedder"><a [routerLink]="'/zedders/'+zed.id">{{zed.firstname}} {{zed.lastname}}</a></div>

        <mat-form-field class="full-width">
          <mat-label>Website URL</mat-label>
          <a [disabled]="!subscriber.suburl" [href]="subscriber.suburl" mat-icon-button matSuffix>
            <mat-icon>link</mat-icon>
          </a>
          <input matInput placeholder="Website URL" formControlName="suburl">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Gender</mat-label>
          <mat-select matNativeControl formControlName="gender">
            <mat-option *ngFor="let gender of genders; index as id" [value]="id">
              {{gender}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
		</mat-card>

    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Delivery Address</mat-card-title>
      </mat-card-header>
      
      <mat-card-content>
        <div formGroupName="delivery_address">
          <mat-form-field class="full-width">
            <mat-label>Address</mat-label>
            <input matInput placeholder="Address" formControlName="address" value="">
          </mat-form-field>

          <div style="display: flex; gap: 8px;">
            <mat-form-field style="flex-grow: 1;">
              <mat-label>Suburb</mat-label>
              <input matInput placeholder="Suburb" formControlName="locality" value="">
            </mat-form-field>

            <mat-form-field style="flex-grow: 1;">
              <mat-label>State</mat-label>
              <input matInput placeholder="State" formControlName="region" value="">
            </mat-form-field>

            <mat-form-field style="flex-grow: 1;">
              <mat-label>Country</mat-label>
              <mat-select matNativeControl formControlName="country">
                <mat-option *ngFor="let country of countries" [value]="country.alpha">
                  {{country.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 160px;">
              <mat-label>Post Code</mat-label>
              <input matInput placeholder="Postal Code" formControlName="postcode" value="">
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
		</mat-card>


    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Radiothon</mat-card-title>
      </mat-card-header>

      <mat-card-content formGroupName="pledge">
        <mat-form-field class="full-width">
          <mat-label>Gold Prize</mat-label>
          <mat-select matNativeControl formControlName="radiothonprizeid">
            <mat-option *ngFor="let prize of prizes" [value]="prize.radiothonprizeid">
              {{prize.radiothonprize }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Special Prize</mat-label>
          <mat-select matNativeControl formControlName="radiothonprize2">
            <mat-option *ngFor="let prize of prizes" [value]="prize.radiothonprizeid">
              {{prize.radiothonprize }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Notes</mat-label>
          <textarea matInput placeholder="Notes" formControlName="pledgenotes" ></textarea>
        </mat-form-field>
      </mat-card-content>
		</mat-card>

  </div>

  <div style="flex: 1">

    <mat-card *ngIf="id === 'search'" class="search-card" >
      <mat-card-header>
        <mat-card-title>Search Paramaters</mat-card-title>
      </mat-card-header>
      <mat-card-content [formGroup]="searchForm">

        <mat-form-field class="full-width">
          <mat-label>Sub #</mat-label>
          <input matInput placeholder="Sub #" formControlName="subnumber">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Name on Card</mat-label>
          <input matInput placeholder="Name on Card" formControlName="nameoncard">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Band Member</mat-label>
          <input matInput placeholder="Band Member" formControlName="bandmember">
        </mat-form-field>
 

        <mat-form-field>
          <mat-label>Start Date (Created)</mat-label>
          <input matInput [matDatepicker]="createdDateStartPicker" formControlName="createdDateStart">
          <mat-datepicker-toggle matIconSuffix [for]="createdDateStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #createdDateStartPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Date (Created)</mat-label>
          <input matInput [matDatepicker]="createdDateEndPicker" formControlName="createdDateEnd">
          <mat-datepicker-toggle matIconSuffix [for]="createdDateEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #createdDateEndPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Start Date (Payment)</mat-label>
          <input matInput [matDatepicker]="paymentDateStartPicker" formControlName="paymentDateStart">
          <mat-datepicker-toggle matIconSuffix [for]="paymentDateStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #paymentDateStartPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Date (Payment)</mat-label>
          <input matInput [matDatepicker]="paymentDateEndPicker" formControlName="paymentDateEnd">
          <mat-datepicker-toggle matIconSuffix [for]="paymentDateEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #paymentDateEndPicker></mat-datepicker>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    
    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Other</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>Favourite Program</mat-label>
            <mat-select matNativeControl formControlName="programid">
              <mat-option *ngFor="let program of programs" [value]="program.programid">
                {{program.programname }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Shirt Size</mat-label>
            <mat-select matNativeControl formControlName="t_size">
              <mat-option *ngFor="let size of shirtSizes" [value]="size">
                {{size }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width">
          <mat-label>Comments</mat-label>
          <textarea matInput placeholder="Comments" formControlName="subcomment" ></textarea>
        </mat-form-field>
      </mat-card-content>
		</mat-card>

    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Subscription Details</mat-card-title>
      </mat-card-header>

      <mat-card-content>

        <div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>Payment Date</mat-label>
            <input matInput [matDatepicker]="paymentpicker" formControlName="paymentdate">
            <mat-datepicker-toggle matIconSuffix [for]="paymentpicker"></mat-datepicker-toggle>
            <mat-datepicker #paymentpicker></mat-datepicker>
          </mat-form-field>

          <mat-checkbox *ngIf="subscriber.pay_it_forward" formControlName="pay_it_forward">Donated</mat-checkbox>
          <mat-checkbox *ngIf="subscriber.pay_the_rent" formControlName="pay_the_rent">Donated</mat-checkbox>

          <mat-form-field class="full-width">
            <mat-label>Expiry Date</mat-label>
            <input matInput [matDatepicker]="expirypicker" formControlName="expirydate">
            <mat-datepicker-toggle matIconSuffix [for]="expirypicker"></mat-datepicker-toggle>
            <mat-datepicker #expirypicker></mat-datepicker>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width">
          <mat-label>Receipt #</mat-label>
          <input matInput placeholder="Receipt" formControlName="receiptnumber">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Created By</mat-label>
          <input matInput placeholder="Created By" formControlName="changeduser">
        </mat-form-field>

        <div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>Sub #</mat-label>
            <button (click)="editSub()" mat-icon-button matPrefix matTooltip="Edit Sub">
              <mat-icon>{{subscriberForm.get('subnumber')!.disabled ? 'lock_open' : 'lock'}}</mat-icon>
            </button>

            <button *ngIf="! subscriberForm.get('subnumber')!.disabled" (click)="genSub()" mat-icon-button matSuffix matTooltip="Generate New Sub Number">
              <mat-icon>flash_on</mat-icon>
            </button>
            <input matInput placeholder="Sub #" formControlName="subnumber">
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Order ID</mat-label>
            <input matInput placeholder="Order ID" formControlName="orderid">
          </mat-form-field>
        </div>

        <div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>Date Created</mat-label>
            <input matInput placeholder="Date Created" formControlName="createddate">
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Date Modified</mat-label>
            <input matInput placeholder="Date Modified" formControlName="changeddate">
          </mat-form-field>
        </div>

        <mat-form-field class="full-width">
          <mat-label>Account Name</mat-label>
          <input matInput placeholder="Account Name" formControlName="cart_customer_name">
        </mat-form-field>

      </mat-card-content>
		</mat-card>

    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Billing Address</mat-card-title>
      </mat-card-header>
      
      <mat-card-content>
        <div formGroupName="billing_address">
          <mat-form-field class="full-width">
            <mat-label>Address</mat-label>
            <input matInput placeholder="Address" formControlName="address">
          </mat-form-field>

          <div style="display: flex; gap: 8px;">
            <mat-form-field style="flex-grow: 1;">
              <mat-label>Suburb</mat-label>
              <input matInput placeholder="Suburb" formControlName="locality">
            </mat-form-field>

            <mat-form-field style="flex-grow: 1;">
              <mat-label>State</mat-label>
              <input matInput placeholder="State" formControlName="region">
            </mat-form-field>

            <mat-form-field style="flex-grow: 1;">
              <mat-label>Country</mat-label>
              <mat-select matNativeControl formControlName="country">
                <mat-option *ngFor="let country of countries" [value]="country.alpha">
                  {{country.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 160px;">
              <mat-label>Post Code</mat-label>
              <input matInput placeholder="Postal Code" formControlName="postcode">
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
		</mat-card>

  </div>
</div>

<div class="floating-fab" [hidden]="!formEdited">
  <button [disabled]="submitLocked" (click)="searchResultsSelection.subid ? goto(searchResultsSelection.subid) : id === 'search' ? searchSubscribers() : saveSubscriber()" mat-fab color="primary" aria-label="Save Subscriber">
    <mat-icon>{{searchResultsSelection.subid ? 'arrow_forward' : id === 'search' ? 'search' : id === 'new' ? 'add' : 'edit' }}</mat-icon>
  </button>
</div>